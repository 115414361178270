import React, { useEffect, useState, useRef, useCallback } from "react";
import {
    useGetInvoiceQuery,
    useGetInvoiceByIdQuery,
    useAddInvoiceMutation,
    useUpdateInvoiceMutation,
    useDeleteInvoiceMutation,
} from "../../../redux/Construction/InvoiceServices";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices";
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { DisabledInput, DateInput, DropdownInput, TextInput } from "../../../Inputs";
import { dropDownListObject, } from '../../../Utils/contructObject';
import { PDFViewer } from '@react-pdf/renderer';
import Modal from "../../../UiComponents/Modal";
import FormReport from "./FormReport";

import { getCommonParams, isGridDatasValid } from "../../../Utils/helper";
import moment from "moment";
import InvoiceDetails from "./InvoiceDetails";
import DeliveryNoteDropdown from "../../../ERP/ReusableComponents/DeliveryNoteDropdown";
import { useGetDeliveryNoteByIdQuery } from "../../../redux/Construction/DeliveryNoteServices";
import tw from "../../../Utils/tailwind-react-pdf";
import PrintFormatInvoice from "./PrintformatInvoice";
import { useGetBranchByIdQuery } from "../../../redux/services/BranchMasterService";
import EInvoice from "./E-Invoice";
import { CIN, COMPANY_NAME, TAN } from "../../../Constants";

const MODEL = "Invoice";

export default function Form() {

    const [readOnly, setReadOnly] = useState(false);
    const [docId, setDocId] = useState("")
    const [id, setId] = useState("");
    const [formReport, setFormReport] = useState(false);
    const [partyId, setPartyId] = useState("");
    const [date, setDate] = useState("");
    const [deliveryNoteId, setDeliveryNoteId] = useState("");
    const [invoiceDetails, setInvoiceDetails] = useState([]);
    const [print, setPrint] = useState(false);
    const [eInvoice, setEInvoice] = useState(false);
    const [shipFromCompany, setShipFromCompany] = useState('');
    const [shipFromAddress, setShipFromAddress] = useState('');
    const [shipFromGstNo, setShipFromGstNo] = useState('');
    const [shipFromPanNo, setShipFromPanNo] = useState('');
    const [shipFromCinNo, setShipFromCinNo] = useState('');
    const [shipFromTanNo, setShipFromTanNo] = useState('');

    const [invoiceNo, setInvoiceNo] = useState('');

    const childRecord = useRef(0);

    const { branchId, companyId, finYearId, userId } = getCommonParams()

    const params = {
        branchId, companyId, finYearId
    };

    const { data: supplierList } =
        useGetPartyQuery({ params: { ...params } });

    const { data: allData, isLoading, isFetching } = useGetInvoiceQuery({ params, searchParams: '' });

    const { data: branchData } = useGetBranchByIdQuery(branchId);

    const getNextDocId = useCallback(() => {
        if (id || isLoading || isFetching) return
        if (allData?.nextDocId) {
            setDocId(allData.nextDocId)
            setInvoiceNo(allData.nextDocId)
        }
    }, [allData, isLoading, isFetching, id])

    useEffect(getNextDocId, [getNextDocId])

    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetInvoiceByIdQuery(id, { skip: !id });

    const [addData] = useAddInvoiceMutation();
    const [updateData] = useUpdateInvoiceMutation();
    const [removeData] = useDeleteInvoiceMutation();


    const setShippingDetails = useCallback(() => {
        if (id) return
        setShipFromCompany(COMPANY_NAME);
        setShipFromAddress(branchData?.data?.address || '');
        setShipFromGstNo(branchData?.data?.gstNo || '')
        setShipFromPanNo(branchData?.data?.panNo || '');
        setShipFromCinNo(CIN);
        setShipFromTanNo(TAN);
    }, [id, branchData])

    useEffect(() => {
        setShippingDetails()
    }, [setShippingDetails])

    const syncFormWithDb = useCallback((data) => {
        if (id) {
            setReadOnly(true);
        } else {
            setReadOnly(false);
        }
        if (data?.docId) {
            setDocId(data?.docId)
        }
        setDate(data?.createdAt ? moment(data?.createdAt).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"));
        setPartyId(data?.ProjectPhase?.Project?.partyId ? data?.ProjectPhase?.Project?.partyId : "");
        setInvoiceDetails(data?.InvoiceDetails || []);
        setDeliveryNoteId(data?.deliveryNoteId || "");
        setPartyId(data?.DeliveryNote?.ProjectPhase?.Project?.partyId || '');
        setShipFromCompany(data?.shipFromCompany || '');
        setShipFromAddress(data?.shipFromAddress || '');
        setShipFromGstNo(data?.shipFromGstNo || '')
        setShipFromPanNo(data?.shipFromPanNo || '');
        setShipFromCinNo(data?.shipFromCinNo || '');
        setShipFromTanNo(data?.shipFromTanNo || '');
        setInvoiceNo(data?.invoiceNo || '');
        if (!data) {
            setShippingDetails()
        }
    }, [id, setShippingDetails]);


    const { data: deliveryNoteData, isLoading: deliveryNoteLoading, isFetching: deliveryNoteFetching } = useGetDeliveryNoteByIdQuery(deliveryNoteId, { skip: id || (!deliveryNoteId) })

    useEffect(() => {
        if (id || deliveryNoteFetching || deliveryNoteLoading) return
        const isDrawingWeight = deliveryNoteData?.data?.ProjectPhase?.Project?.isDrawingWeight;
        setInvoiceDetails(deliveryNoteData?.data?.DeliveryNoteGroupedDetails.map(i => ({ groupName: i.groupName, qty: isDrawingWeight ? i.weight : i.physical_weight, rate: i?.rate || 0, tax: i?.tax || 0, hsn: i?.hsn, deliveryNoteGroupedDetailsId: i.id, uom: i.uom })) || []);
    }, [id, deliveryNoteData, deliveryNoteFetching, deliveryNoteLoading])



    const isIGst = singleData?.data?.DeliveryNote?.ProjectPhase?.Project?.Party?.iGst;

    useEffect(() => {
        if (id) {
            syncFormWithDb(singleData?.data);
        } else {
            syncFormWithDb(undefined);
        }
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    const data = {
        branchId, id, userId,
        finYearId,
        deliveryNoteId,
        invoiceDetails,
        shipFromAddress, shipFromCinNo, shipFromCompany, shipFromGstNo, shipFromPanNo, shipFromTanNo, invoiceNo
    }


    const validateData = (data) => {
        let mandatoryFields = ["qty", "rate", "tax"];
        return data.deliveryNoteId && (data.invoiceDetails.length > 0) && isGridDatasValid(data.invoiceDetails, false, mandatoryFields)
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData;
            if (text === "Updated") {
                returnData = await callback(data).unwrap();
            } else {
                returnData = await callback(data).unwrap();
            }
            if (returnData.statusCode === 1) {
                toast.error(returnData.message);
                return
            } else {
                setId(returnData?.data?.id)
                // onNew()
                toast.success(text + "Successfully");
            }
        } catch (error) {
            console.log("handle");
        }
    };


    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", { position: "top-center" })
            return
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    }

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                onNew();
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setReadOnly(false);
        syncFormWithDb(undefined)
        getNextDocId()
    };


    const tableHeadings = ["PoNo", "PoDate", "transType", "DueDate", "Supplier"]
    const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']

    let supplierListBasedOnSupply = supplierList ? supplierList.data : []
    return (
        <>
            <Modal isOpen={print} onClose={() => { setPrint(false) }} widthClass={"w-[90%] h-[90%]"} >
                <PDFViewer style={tw("w-full h-full")}>
                    <PrintFormatInvoice isIGst={isIGst} key={singleData?.data} data={singleData?.data} branchData={branchData?.data} />
                </PDFViewer>
            </Modal>
            <Modal isOpen={eInvoice} onClose={() => { setEInvoice(false) }} widthClass={"w-[50%] h-[50%] "} >
                <EInvoice id={id} onClose={() => { setEInvoice(false) }} irn={singleData?.data?.irn} ewayBillNo={singleData?.data?.ewayBillNo} transporterId={singleData?.data?.transId} />
            </Modal>
            <div
                onKeyDown={handleKeyDown}
                className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto">
                <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
                    <FormReport
                        heading={MODEL}
                        tableHeaders={tableHeadings}
                        tableDataNames={tableDataNames}
                        loading={
                            isLoading || isFetching
                        }
                        tableWidth="100%"
                        data={allData?.data}
                        onClick={(id) => {
                            setId(id);
                            setFormReport(false);
                        }
                        }
                    />
                </Modal>
                <div className="flex flex-col frame w-full h-full">
                    <FormHeader
                        onNew={onNew}
                        model={MODEL}
                        saveData={saveData}
                        setReadOnly={setReadOnly}
                        deleteData={deleteData}
                        openReport={() => { setFormReport(true) }}
                        childRecord={childRecord.current}
                        onPrint={id ? () => { setPrint(true) } : null}
                    />
                    <div className="flex-1 grid gap-x-2">
                        <div className="col-span-3 grid ">
                            <div className='col-span-3 grid '>
                                <div className='mr-1'>
                                    <div className={`grid`}>
                                        <div className={"flex flex-col"}>
                                            <fieldset className='frame rounded-tr-lg rounded-bl-lg w-full border border-gray-600 px-3 overflow-auto h-[120px]'>
                                                <legend className='sub-heading'>Info</legend>
                                                <div className='flex flex-col justify-center items-start flex-1 w-full'>
                                                    <div className="grid grid-cols-5 w-full">
                                                        {/* <DisabledInput name="Doc Id." value={docId} required={true}
                                                        /> */}
                                                        <TextInput name={"Invoice No."} value={invoiceNo} setValue={setInvoiceNo} readOnly={readOnly} />
                                                        <DateInput name="Doc Date" value={date} type={"date"} required={true} readOnly={readOnly} disabled />
                                                        <DropdownInput name="Customer" options={dropDownListObject(supplierListBasedOnSupply, "aliasName", "id")} value={partyId} setValue={setPartyId} required={true} readOnly={id} />
                                                        <DeliveryNoteDropdown partyId={partyId} multiSelect={false} withoutLabel={false} readOnly={id} name={"Delivery Note"} selected={deliveryNoteId} setSelected={setDeliveryNoteId} />
                                                        {id && (!singleData?.data?.ewayBillNo) &&
                                                            <button onClick={() => { setEInvoice(true) }} className="bg-green-600 text-white p-1 rounded">
                                                                E-Invoice
                                                            </button>
                                                        }
                                                        <TextInput name={"Ship From"} value={shipFromCompany} setValue={setShipFromCompany} readOnly={readOnly} />
                                                        <TextInput name={"Ship.Address"} value={shipFromAddress} setValue={setShipFromAddress} readOnly={readOnly} />
                                                        <TextInput name={"Gst No"} value={shipFromGstNo} setValue={setShipFromGstNo} readOnly={readOnly} />
                                                        <TextInput name={"Pan No"} value={shipFromPanNo} setValue={setShipFromPanNo} readOnly={readOnly} />
                                                        <TextInput name={"CIN No"} value={shipFromCinNo} setValue={setShipFromCinNo} readOnly={readOnly} />
                                                        <TextInput name={"Tan No"} value={shipFromTanNo} setValue={setShipFromTanNo} readOnly={readOnly} />
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg my-1 w-full border border-gray-600 md:pb-5 flex flex-1 overflow-auto'>
                                                <legend className='sub-heading'>Invoice Details</legend>
                                                <InvoiceDetails isIGst={isIGst} deliveryNoteId={deliveryNoteId} invoiceDetails={invoiceDetails} setInvoiceDetails={setInvoiceDetails} />
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}