import React, { useMemo } from 'react'
import { useEffect, useState } from "react";
import { Loader } from "../../../Basic/components";
import { getCommonParams } from "../../../Utils/helper";
import { showEntries } from '../../../Utils/DropdownData';
import secureLocalStorage from 'react-secure-storage';
import { pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from '../../../Utils/helper';
import ReactPaginate from 'react-paginate';
import { useGetDeliveryNoteQuery } from '../../../redux/Construction/DeliveryNoteServices';


const OutstandingsReport = () => {
    const branchId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "currentBranchId"
    )
    const [searchProjectName, setSearchProjectName] = useState("");
    const [searchPhaseName, setSearchProjectPhaseName] = useState("");
    const [searchItemName, setSearchItemName] = useState('')

    const [dataPerPage, setDataPerPage] = useState("10");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [partyId, setPartyId] = useState("");
    const handleOnclick = (e) => {
        setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
    }
    const searchFields = useMemo(() => ({ searchProjectName, searchPhaseName, partyId, searchItemName }), [searchProjectName, searchPhaseName, partyId, searchItemName])

    useEffect(() => { setCurrentPageNumber(1) }, [searchFields])

    const { finYearId } = getCommonParams()

    const { data: allData, isLoading, isFetching } = useGetDeliveryNoteQuery({ params: { branchId, ...searchFields, pagination: true, dataPerPage, pageNumber: currentPageNumber, finYearId, outStandingReport: true, partyId } });


    useEffect(() => {
        if (allData?.totalCount) {
            setTotalCount(allData?.totalCount)
        }
    }, [allData, isLoading, isFetching])


    const isLoadingIndicator = isLoading || isFetching


    return (
        <div className="flex flex-col w-full h-[95%] overflow-auto" >
            <div className="md:flex md:items-center md:justify-between page-heading p-1">
                <div className="heading text-center md:mx-10 capitalize">OUTSTANDING'S REPORT</div>
                {/* <PartyDropdown selectName="All" multiSelect={false} withoutLabel={false} name={"Party"} selected={partyId} setSelected={setPartyId} clear /> */}
                <div className=" sub-heading justify-center md:justify-start items-center">
                    <label className="text-white text-sm rounded-md m-1  border-none">Show Entries</label>
                    <select value={dataPerPage}
                        onChange={(e) => setDataPerPage(e.target.value)} className='h-6 w-40 border border-gray-500 rounded mr-9'>
                        {showEntries.map((option) => <option value={option.value} >{option.show}</option>)}
                    </select>
                </div>
            </div>
            <>
                <div
                    className="h-[500px] w-full overflow-auto"
                >
                    <table className="table-auto text-center w-full">
                        <thead className="border-2 table-header ">
                            <tr className='h-2'>
                                <th
                                    className="border-2  top-0 stick-bg">
                                    S. no.
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg whitespace-nowrap"
                                >
                                    <label>Project</label>
                                    <input
                                        type="text"
                                        className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                                        placeholder="Search"
                                        value={searchProjectName}
                                        onChange={(e) => {
                                            setSearchProjectName(e.target.value);
                                        }}
                                    />
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg"
                                >
                                    <label className='whitespace-nowrap'>Phase</label><input
                                        type="text"
                                        className="text-black  h-6 focus:outline-none border md:ml-3 border-gray-400 w-[70%] rounded-lg"
                                        placeholder="Search"
                                        value={searchPhaseName}
                                        onChange={(e) => {
                                            setSearchProjectPhaseName(e.target.value);
                                        }}
                                    />
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg "
                                >
                                    <label className='whitespace-nowrap'>Name</label><input
                                        type="text"
                                        className="text-black w-[70%] h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                                        placeholder="Search"
                                        value={searchItemName}
                                        onChange={(e) => {
                                            setSearchItemName(e.target.value);
                                        }}
                                    />
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg whitespace-nowrap"
                                >
                                    <label>Uom</label>
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg whitespace-nowrap"
                                >
                                    <label>Req. Qty</label>
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg whitespace-nowrap"
                                >
                                    <label>Req. Weight</label>
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg whitespace-nowrap"
                                >
                                    <label>Delivery</label>
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg whitespace-nowrap"
                                >
                                    <label>Bal.</label>
                                </th>
                            </tr>
                        </thead>
                        {isLoadingIndicator ?
                            <tbody>
                                <tr>
                                    <td>
                                        <Loader />
                                    </td>
                                </tr>
                            </tbody>
                            :
                            <tbody className="border-2">
                                {(allData?.data || []).map((dataObj, index) => (
                                    <tr
                                        key={dataObj.id}
                                        className="border-2 table-row "
                                    >
                                        <td className='py-1 whitespace-nowrap'> {(index + 1) + (dataPerPage * (currentPageNumber - 1))}</td>
                                        <td className='py-1 whitespace-nowrap'>{dataObj?.project} </td>
                                        <td className='py-1 whitespace-nowrap'> {dataObj.phase}</td>
                                        <td className='py-1 whitespace-nowrap'> {dataObj.itemName}</td>
                                        <td className='py-1 whitespace-nowrap'> {dataObj.uom}</td>
                                        <td className='py-1 whitespace-nowrap'> {dataObj.reqQty}</td>
                                        <td className='py-1 whitespace-nowrap'> {dataObj.reqWeight}</td>
                                        <td className='py-1 whitespace-nowrap'> {dataObj.delQty}</td>
                                        <td className='py-1 whitespace-nowrap'> {dataObj.balQty}</td>
                                    </tr>
                                ))}
                            </tbody>
                        }
                    </table>
                </div>
            </>
            <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                forcePage={pageNumberToReactPaginateIndex(currentPageNumber)}
                pageCount={Math.ceil(totalCount / dataPerPage)}
                marginPagesDisplayed={1}
                onPageChange={handleOnclick}
                containerClassName={"flex justify-center m-2 gap-5 items-center"}
                pageClassName={"border custom-circle text-center"}
                disabledClassName={"p-1 bg-gray-200"}
                previousLinkClassName={"border p-1 text-center"}
                nextLinkClassName={"border p-1"}
                activeClassName={"bg-blue-900 text-white px-2"} />
        </div>
    )
}

export default OutstandingsReport