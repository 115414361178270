import React from 'react'
import SelectAndCreateComponent from './SelectAndCreateComponent'
import { useAddProjectPhaseMutation, useGetProjectPhaseQuery } from '../../redux/Construction/ProjectPhaseServices'
import { DropdownInput } from '../../Inputs'
import { dropDownListObject } from '../../Utils/contructObject'

export default function ProjectPhaseSelectAndCreateComponent({ projectPhaseId, setProjectPhaseId, projectId, readOnly, dropDownInput, required }) {
    const { data } = useGetProjectPhaseQuery({ params: { projectId } })
    const [addProjectPhase] = useAddProjectPhaseMutation()
    const handleAddNewProject = async (name) => {
        const response = await addProjectPhase({ projectId, name }).unwrap()
        return response.data.id
    }
    return (
        <>
            {dropDownInput
                ?
                <DropdownInput required name="Phase" options={dropDownListObject(data?.data || [], "name", "id")} value={projectPhaseId} setValue={setProjectPhaseId} readOnly={readOnly} />
                :
                <SelectAndCreateComponent required name={"Project Phase"} readOnly={readOnly} setItemId={setProjectPhaseId} itemId={projectPhaseId} options={data?.data || []} handleAddNewProject={handleAddNewProject} />
            }
        </>
    )
}
