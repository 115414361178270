import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import qrCode from 'qrcode';
import saint from '../../../assets/logoim.jpg';
import { CIN, COMPANY_NAME, TAN } from '../../../Constants';
import { getDateFromDateTimeToDisplay } from '../../../Utils/helper';

const PrintMain = ({ data, address }) => {
    const generateQRCode = async (value) => {
        try {
            const qrDataURL = await qrCode.toDataURL(value);
            return qrDataURL;
        } catch (error) {
            console.error('Error generating QR code:', error);
            return null;
        }
    };
    const qrCodeValue = data?.eInvoiceResponse?.SignedQRCode || '';
    return (
        <View style={tw("text-[7px] w-full")}>
            <View style={tw(" text-base border-x w-full border-gray-500 h-[50px]")}>
                <View style={tw("flex flex-row items-center h-full w-full ")}>
                    <View style={tw("flex flex-row items-center justify-center w-1/4 h-full border-r border-gray-500")}>
                        <Image style={tw("w-[100px] object-fit")} src={saint} />
                    </View>
                    <View style={tw("w-3/4 flex flex-col items-center justify-center h-full ")}>
                        <Text style={tw(" font-bold text-sky-900")}>
                            {COMPANY_NAME}
                        </Text>
                        <Text style={tw(" text-gray-700 mb-1 text-xs")}>
                            Corp Off: 114, Shree Arthanari Towers, Sixth Floor, Race Course Rd, Coimbatore, Tamil Nadu 641018
                        </Text>
                        <Text style={tw(" text-gray-700 mb-1 text-xs")}>
                            No. 6-948/3, DVR, RW No.06, Jaggaiahpet, NTR, ANDHRA PRADESH, 521175
                        </Text>
                        <Text style={tw(" text-gray-700 text-xs")}>
                            Tel: 96130 56666, Website: <Text style={tw("text-blue-500 underline")}>www.Hansem.in</Text>
                        </Text>
                    </View>
                </View>
            </View>
            <View style={tw("border w-full flex flex-row border-gray-500 h-[140px]")}>
                <View style={tw("w-2/3 h-full")}>
                    <View style={tw("w-full h-[50%] flex flex-row border-b border-gray-500")}>
                        <View style={tw("w-1/2 border-r h-full border-gray-500")}>
                            <Text style={tw(" font-semibold text-sky-900 text-xs")}>
                                Supplier's Address &nbsp;:
                            </Text>
                            <Text style={tw(" font-medium text-sky-900 text-xs")}>
                                {COMPANY_NAME}
                            </Text>
                            <Text style={tw(" text-gray-700 ")}>
                                {data?.Branch?.address}
                            </Text>
                            <Text style={tw(" text-gray-700 ")}>
                                GSTIN&nbsp;:{data?.Branch?.gstNo}
                            </Text>
                            <Text style={tw(" text-gray-700 ")}>
                                PAN&nbsp;:{data?.Branch?.panNo}
                            </Text>
                            <Text style={tw(" text-gray-700 ")}>
                                CIN&nbsp;:{CIN}
                            </Text>
                            <Text style={tw(" text-gray-700 text-justify")}>
                                TAN&nbsp;:{TAN}
                            </Text>
                        </View>
                        <View style={tw("w-1/2 border-r h-full border-gray-500")}>
                            <Text style={tw(" font-semibold text-sky-900 text-xs")}>
                                Ship From&nbsp;:
                            </Text>
                            <Text style={tw(" font-medium text-sky-900 text-xs")}>
                                {data?.shipFromCompany}
                            </Text>
                            <Text style={tw(" text-gray-700 ")}>
                                {data?.shipFromAddress}
                            </Text>
                            <Text style={tw(" text-gray-700 ")}>
                                GSTIN&nbsp;:{data?.shipFromGstNo}
                            </Text>
                            <Text style={tw(" text-gray-700 ")}>
                                PAN&nbsp;:{data?.shipFromPanNo}
                            </Text>
                            <Text style={tw(" text-gray-700 ")}>
                                CIN&nbsp;:{data?.shipFromCinNo}
                            </Text>
                            <Text style={tw(" text-gray-700 text-justify")}>
                                TAN&nbsp;:{data?.shipFromTanNo}
                            </Text>
                        </View>
                    </View>
                    <View style={tw("w-full h-[50%]")}>
                        <View style={tw("w-full border-r border-gray-500 flex flex-row h-1/6")}>
                            <View style={tw("flex flex-row w-1/2  h-full border-b border-r border-gray-500")}>
                                <View style={tw("w-1/3 flex items-center justify-center border-r border-gray-500  text-black  h-full")}>
                                    <Text style={tw(" text-gray-700 ")}>Place of supply</Text>
                                </View>
                                <View style={tw("w-2/3 flex items-center justify-center text-black h-full")}>
                                    <Text style={tw(" text-gray-700 ")}>{data?.DeliveryNote?.ProjectPhase?.Project?.location}</Text>
                                </View>
                            </View>
                            <View style={tw("flex flex-row w-1/2  h-full border-b border-r border-gray-500")}>
                                <View style={tw("w-1/3 flex items-center justify-center border-r border-gray-500  text-black  h-full")}>
                                    <Text style={tw(" text-gray-700 ")}>Invoice No</Text>
                                </View>
                                <View style={tw("w-2/3 flex items-center justify-center text-black ")}>
                                    <Text style={tw(" text-gray-700 ")}>{data?.invoiceNo}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={tw("w-full border-r border-gray-500 flex flex-row h-1/6")}>
                            <View style={tw("flex flex-row w-1/2  h-full border-b border-r border-gray-500")}>
                                <View style={tw("w-1/3 flex items-center justify-center border-r border-gray-500  text-black ")}>
                                    <Text style={tw(" text-gray-700 ")}>Sales Office</Text>
                                </View>
                                <View style={tw("w-2/3 flex items-center justify-center  text-black ")}>
                                    <Text style={tw(" text-gray-700 ")}></Text>
                                </View>
                            </View>
                            <View style={tw("flex flex-row w-1/2  h-full border-b border-r border-gray-500")}>
                                <View style={tw("w-1/3 flex items-center justify-center border-r border-gray-500  text-black ")}>
                                    <Text style={tw(" text-gray-700 ")}>Date</Text>
                                </View>
                                <View style={tw("w-2/3 flex items-center justify-center  text-black ")}>
                                    <Text style={tw(" text-gray-700 ")}>{getDateFromDateTimeToDisplay(data?.createdAt)}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={tw("w-full border-r border-gray-500 flex flex-row h-1/6")}>
                            <View style={tw("flex flex-row w-1/2  h-full border-b border-r border-gray-500")}>
                                <View style={tw("w-1/3 flex items-center justify-center border-r border-gray-500  text-black ")}>
                                    <Text style={tw(" text-gray-700 ")}>PO</Text>
                                </View>
                                <View style={tw("w-2/3 flex items-center justify-center  text-black ")}>
                                    <Text style={tw(" text-gray-700 ")}></Text>
                                </View>
                            </View>
                            <View style={tw("flex flex-row w-1/2  h-full border-b border-r border-gray-500")}>
                                <View style={tw("w-1/3 flex items-center justify-center border-r border-gray-500  text-black ")}>
                                    <Text style={tw(" text-gray-700 ")}>Vechile No</Text>
                                </View>
                                <View style={tw("w-2/3 flex items-center justify-center  text-black ")}>
                                    <Text style={tw(" text-gray-700 ")}>{data?.DeliveryNote?.truckNo}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={tw("w-full border-r border-gray-500 flex flex-row h-1/6")}>
                            <View style={tw("flex flex-row w-1/2  h-full border-b border-r border-gray-500")}>
                                <View style={tw("w-1/3 flex items-center justify-center border-r border-gray-500  text-black ")}>
                                    <Text style={tw(" text-gray-700 ")}>SO</Text>
                                </View>
                                <View style={tw("w-2/3 flex items-center justify-center  text-black ")}>
                                    <Text style={tw(" text-gray-700 ")}></Text>
                                </View>
                            </View>
                            <View style={tw("flex flex-row w-1/2  h-full border-b border-r border-gray-500")}>
                                <View style={tw("w-1/3 flex items-center justify-center border-r border-gray-500  text-black ")}>
                                    <Text style={tw(" text-gray-700 ")}>LR.DocketNo</Text>
                                </View>
                                <View style={tw("w-2/3 flex items-center justify-center  text-black ")}>
                                    <Text style={tw(" text-gray-700 ")}></Text>
                                </View>
                            </View>
                        </View>
                        <View style={tw("w-full border-r border-gray-500 flex flex-row h-1/6")}>
                            <View style={tw("flex flex-row w-full  h-full border-b border-r border-gray-500")}>
                                <View style={tw("w-1/6 flex items-center justify-center border-r border-gray-500  text-black ")}>
                                    <Text style={tw(" text-gray-700 ")}>IRN</Text>
                                </View>
                                <View style={tw("w-5/6 flex items-start justify-center text-black ")}>
                                    <Text style={tw(" text-gray-700 pl-1")}>
                                        {data?.irn}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={tw("w-full border-r border-gray-500 flex flex-row h-1/6")}>
                            <View style={tw("flex flex-row w-1/2  h-full border-b border-r border-gray-500")}>
                                <View style={tw("w-1/3 flex items-center justify-center border-r border-gray-500  text-black ")}>
                                    <Text style={tw(" text-gray-700 ")}>ACK NO</Text>
                                </View>
                                <View style={tw("w-2/3 flex items-center justify-center  text-black ")}>
                                    <Text style={tw(" text-gray-700 ")}>
                                        {data?.ackNo}
                                    </Text>
                                </View>
                            </View>
                            <View style={tw("flex flex-row w-1/2  h-full border-b border-r border-gray-500")}>
                                <View style={tw("w-1/3 flex items-center justify-center border-r border-gray-500  text-black ")}>
                                    <Text style={tw(" text-gray-700 ")}>ACK DATE</Text>
                                </View>
                                <View style={tw("w-2/3 flex items-center justify-center  text-black ")}>
                                    <Text style={tw(" text-gray-700 ")}>
                                        {data?.eInvoiceResponse?.AckDt}
                                    </Text>
                                </View>
                            </View>
                        </View>

                    </View>
                </View>
                <View style={tw("w-1/3 h-full")}>
                    <Text style={tw("text-center text-sm")}>
                        e-Invoice
                    </Text>
                    <Image src={generateQRCode(qrCodeValue)} style={tw("w-full h-full")} />
                </View>
            </View>
            <View style={tw("w-full border-gray-500 flex flex-row h-[90px]")}>
                <View style={tw("w-1/2 h-full flex flex-col items-center border-r border-l border-gray-500")}>
                    <View style={tw("h-[60%] w-full pl-1 flex flex-col ")}>
                        <Text style={tw(" font-bold text-sky-900")}>
                            Details of Buyer (Billed/Invoiced to):
                        </Text>
                        <Text style={tw(" text-gray-700 mt-2 font-[Times-Bold]")}>
                            {data?.DeliveryNote?.ProjectPhase?.Project?.Party?.name}
                        </Text>
                        <Text style={tw(" text-gray-700 ")}>
                            {data?.DeliveryNote?.ProjectPhase?.Project?.Party?.address}
                        </Text>
                        <Text style={tw(" text-gray-700 ")}>
                            State: {data?.DeliveryNote?.ProjectPhase?.Project?.Party?.City?.state?.name}
                        </Text>
                        <Text style={tw(" text-gray-700 ")}>
                            GSTN: {data?.DeliveryNote?.ProjectPhase?.Project?.Party?.gstNo}   PAN: {data?.DeliveryNote?.ProjectPhase?.Project?.Party?.panNo}
                        </Text>
                    </View>
                    <View style={tw("w-full border-t text-gray-700 border-gray-500 flex flex-col h-[25%] pl-1")}>
                        <View style={tw("flex justify-center h-1/2")}>
                            <Text style={tw("")}>
                                Way Bill No.:
                            </Text>
                        </View>
                        <View style={tw("w-full flex flex-row h-1/2 justify-center items-center")}>
                            <View style={tw("w-1/2 h-full")}>
                                <Text>
                                    Way Bill Date: {getDateFromDateTimeToDisplay(data?.createdAt)}
                                </Text>
                            </View>
                            <View style={tw("w-1/2 h-full")}>
                                <Text>
                                    Waybill Exp Date: {getDateFromDateTimeToDisplay(data?.createdAt)}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={tw("w-full border-t border-gray-500 w-full text-gray-700 flex flex-row items-center h-[15%]")}>
                        <Text style={tw(" ")}>
                            &nbsp;Payment terms / Incoterms : 100% Advance Payment</Text>
                    </View>
                </View>
                <View style={tw("w-1/2 h-full flex flex-col items-center border-r border-gray-500")}>
                    <View style={tw("h-[60%] w-full pl-1 flex flex-col")}>
                        <Text style={tw(" font-bold text-sky-900")}>
                            Details of Consignee (Shipped to):
                        </Text>
                        <Text style={tw(" text-gray-700 mt-2 font-[Times-Bold]")}>
                            {data?.DeliveryNote?.ProjectPhase?.Project?.Party?.name}
                        </Text>
                        <Text style={tw(" text-gray-700 ")}>
                            {data?.DeliveryNote?.ProjectPhase?.Project?.workSiteAddress}
                        </Text>
                        <Text style={tw(" text-gray-700 ")}>
                            GSTN: {data?.DeliveryNote?.ProjectPhase?.Project?.Party?.gstNo}  PAN: {data?.DeliveryNote?.ProjectPhase?.Project?.Party?.panNo}
                        </Text>
                    </View>
                    <View style={tw("w-full border-t text-gray-700 border-gray-500 flex flex-col h-[25%] pl-1")}>
                        <View style={tw("flex justify-center h-1/2")}>
                            <Text style={tw("")}>
                                Transporter
                            </Text>
                        </View>
                        <View style={tw("flex justify-center h-1/2")}>
                            <Text style={tw("")}>
                                Shipment Mode:
                            </Text>
                        </View>
                    </View>
                    <View style={tw("h-[15%] w-full border-t border-gray-500 w-full text-gray-700 flex flex-row items-center")}>
                        <Text style={tw("")}>
                            &nbsp;Driver Details: {data?.DeliveryNote?.driverName}
                        </Text>
                    </View>
                </View>
            </View>
        </View >
    );
}

export default PrintMain;
