import React from 'react'
import SelectAndCreateComponent from './SelectAndCreateComponent'
import { useAddProjectMutation, useGetProjectQuery } from '../../redux/Construction/ProjectServices'
import { DropdownInput } from '../../Inputs'
import { dropDownListObject } from '../../Utils/contructObject'
import { toast } from 'react-toastify'

export default function ProjectNameSelectAndCreateComponent({ projectId, setProjectId, partyId, readOnly, dropDownInput }) {
    const { data } = useGetProjectQuery({ params: { partyId } })
    const [addProject] = useAddProjectMutation()
    const handleAddNewProject = async (name) => {
        if (!partyId) {
            return toast.error("Select Party to Create Project...!");
        }
        const response = await addProject({ partyId, name }).unwrap()
        if (response.statusCode === 1) {
            return toast.error(response.message);
        }
        return response.data.id
    }
    return (
        <>
            {dropDownInput
                ?
                <DropdownInput name="Project" options={dropDownListObject(data?.data || [], "name", "id")} value={projectId} setValue={setProjectId} required={true} readOnly={readOnly} />
                :
                <SelectAndCreateComponent readOnly={readOnly} name={"Project"} setItemId={setProjectId} itemId={projectId} options={data?.data || []} handleAddNewProject={handleAddNewProject} />
            }
        </>
    )
}
