import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery"; import { CUTTING_EXCESS_FABRIC_RETURN_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const CuttingExcessFabricReturnApi = createApi({
  reducerPath: "CuttingExcessFabricReturn",
  baseQuery,
  tagTypes: ["CuttingExcessFabricReturn"],
  endpoints: (builder) => ({
    getCuttingExcessFabricReturn: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: CUTTING_EXCESS_FABRIC_RETURN_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: CUTTING_EXCESS_FABRIC_RETURN_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["CuttingExcessFabricReturn"],
    }),
    getCuttingExcessFabricReturnById: builder.query({
      query: ({ id }) => {
        return {
          url: `${CUTTING_EXCESS_FABRIC_RETURN_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["CuttingExcessFabricReturn"],
    }),
    addCuttingExcessFabricReturn: builder.mutation({
      query: (payload) => ({
        url: CUTTING_EXCESS_FABRIC_RETURN_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["CuttingExcessFabricReturn"],
    }),
    updateCuttingExcessFabricReturn: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${CUTTING_EXCESS_FABRIC_RETURN_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["CuttingExcessFabricReturn"],
    }),
    deleteCuttingExcessFabricReturn: builder.mutation({
      query: (id) => ({
        url: `${CUTTING_EXCESS_FABRIC_RETURN_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CuttingExcessFabricReturn"],
    }),
  }),
});

export const {
  useGetCuttingExcessFabricReturnQuery,
  useGetCuttingExcessFabricReturnByIdQuery,
  useAddCuttingExcessFabricReturnMutation,
  useUpdateCuttingExcessFabricReturnMutation,
  useDeleteCuttingExcessFabricReturnMutation,
} = CuttingExcessFabricReturnApi;

export default CuttingExcessFabricReturnApi;
