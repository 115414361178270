import React from 'react'
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf'
import hansomLogo from "../../../assets/HansemLogo.png"
import { getDateFromDateTimeToDisplay } from '../../../Utils/helper';
import Page2 from './Page2';
import { COMPANY_NAME } from '../../../Constants';

const PrintFormat = ({ data, isDrawingWeight }) => {
    const items = data?.DeliveryNoteGroupedDetails || [];
    let totalWeight = items.reduce((a, groupItem) => a + (
        (parseFloat(isDrawingWeight ? (groupItem?.items.reduce((a, c) => a + parseFloat(c.weight), 0)) : (groupItem?.physical_weight)) * parseFloat(groupItem?.items.reduce((a, c) => a + parseFloat(c.qty), 0)))
    ), 0);
    if (isNaN(totalWeight)) {
        totalWeight = items?.[0]?.items?.[0]?.qty;
    }
    let totalQty = items.reduce((a, c) => a + parseFloat(c?.items.reduce((a, c) => a + parseFloat(c.qty), 0)), 0);
    if (isNaN(totalQty)) {
        totalQty = items?.[0]?.items?.[0]?.qty;
    }
    let count = 1;
    return (
        <Document style={tw("w-full h-full")} >
            <Page size="A4" style={{ fontFamily: "Times-Roman", ...tw("relative pb-[50px] p-2") }}>
                <View fixed style={tw("w-full")}>
                    <View style={tw("text-center text-xl flex flex-row w-full h-[55px] border-x border-t border-gray-500 items-center")}>
                        <View style={tw("w-1/2 flex flex-row items-center justify-center")}>
                            <Image style={tw("w-[200px] object-fit")} src={hansomLogo} />
                        </View>
                        <View style={tw("w-1/2 p-1")}>
                            <View style={tw("border-2 border-blue-700 rounded-md text-sm p-2")}>
                                <Text>
                                    {COMPANY_NAME}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={tw("text-center text-xl border border-gray-500 flex flex-row items-center justify-center h-[30px]")}>
                        <Text style={{ fontFamily: "Times-Bold" }}> DELIVERY NOTE</Text>
                    </View>
                </View>
                <View style={tw('w-full text-sm border-x border-b border-gray-500')}>
                    <View style={tw("flex flex-row h-[80px] border-b border-gray-500")}>
                        <View style={tw("w-7/12 h-full border-r border-gray-500 flex flex-row")}>
                            <View style={tw("w-1/4 border-r border-gray-500 text-center h-full flex items-center justify-center")}>
                                <Text>Client</Text>
                            </View>
                            <View style={tw("w-3/4 h-full flex flex-col justify-center items-center")}>
                                <Text style={tw("text-sm no-word")}>
                                    {data?.ProjectPhase?.Project?.Party?.name}
                                </Text>
                                <Text style={tw("text-xs")}>
                                    {data?.ProjectPhase?.Project?.Party?.address}
                                </Text>
                            </View>
                        </View>
                        <View style={tw("w-5/12 h-full")}>
                            <View style={tw("h-1/3 w-full flex  flex-row items-center justify-center border-b  border-gray-500")}>
                                <View style={tw("w-1/2 h-full border-r border-gray-500 flex flex-row items-center")}>
                                    <Text>
                                        &nbsp;Date&nbsp;:
                                    </Text>
                                </View>
                                <View style={tw("w-1/2 h-full flex flex-row items-center justify-center")}>
                                    <Text>
                                        {getDateFromDateTimeToDisplay(data?.createdAt)}
                                    </Text>
                                </View>
                            </View>
                            <View style={tw("h-1/3 w-full flex  flex-row items-center justify-center border-b  border-gray-500")}>
                                <View style={tw("w-1/2 h-full border-r border-gray-500 flex flex-row items-center")}>
                                    <Text>
                                        &nbsp;DN&nbsp;No&nbsp;:
                                    </Text>
                                </View>
                                <View style={tw("w-1/2 h-full flex flex-row items-center justify-center")}>
                                    <Text>
                                        {data?.docId}
                                    </Text>
                                </View>
                            </View>
                            <View style={tw("h-1/3 w-full flex flex-row items-center justify-center border-gray-500")}>
                                <View style={tw("w-1/2 h-full border-r border-gray-500 flex flex-row items-center")}>
                                    <Text>
                                        &nbsp;CONTAINER&nbsp;No&nbsp;:
                                    </Text>
                                </View>
                                <View style={tw("w-1/2 h-full flex flex-row items-center justify-center")}>
                                    <Text>
                                        {data?.containerNo}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={tw("flex w-full flex-row border-b border-gray-500 h-[20px]")}>
                        <View style={tw("w-7/12 flex flex-row items-center justify-center border-r border-gray-500")}>
                            <View style={tw("w-1/4 h-full border-r border-gray-500  flex items-center justify-center")}>
                                <Text>
                                    &nbsp;Job&nbsp;No&nbsp;:
                                </Text>
                            </View>
                            <View style={tw("w-3/4 h-full flex flex-row items-center justify-center text-xs")}>
                                <Text>
                                    {data?.ProjectPhase?.Project?.jobNo}
                                </Text>
                            </View>
                        </View>
                        <View style={tw("w-5/12 flex flex-row items-center justify-center border-gray-500")}>
                            <View style={tw("w-1/2 h-full border-r border-gray-500")}>
                                <Text>
                                    &nbsp;TRUCK&nbsp;No&nbsp;:
                                </Text>
                            </View>
                            <View style={tw("w-1/2 h-full flex flex-row items-center justify-center")}>
                                <Text>
                                    {data?.truckNo}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={tw("flex w-full flex-row border-gray-500 h-[20px]")}>
                        <View style={tw("w-7/12 flex flex-row items-center justify-center border-r border-gray-500")}>
                            <View style={tw("w-1/4 h-full border-r border-gray-500  flex items-center justify-center")}>
                                <Text>
                                    &nbsp;Location&nbsp;:
                                </Text>
                            </View>
                            <View style={tw("w-3/4 h-full border-r border-gray-500  flex flex-row items-center justify-center text-xs")}>
                                <Text>
                                    {data?.ProjectPhase?.Project?.location}
                                </Text>
                            </View>
                        </View>
                        <View style={tw("w-5/12 flex flex-row items-center justify-center border-gray-500")}>
                            <View style={tw("w-1/2 h-full border-r border-gray-500")}>
                                <Text>
                                    &nbsp;Driver&nbsp;Name&nbsp;:
                                </Text>
                            </View>
                            <View style={tw("w-1/2 h-full flex flex-row items-center justify-center")}>
                                <Text>
                                    {data?.driverName}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={tw("w-full text-xs border-x border-b border-gray-500 text-center flex flex-row h-[20px]")}>
                    <View style={tw("flex flex-row  border-r border-gray-500 justify-center items-center w-[10%]")}>
                        <Text>
                            S.No
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[37%]")}>
                        <Text>
                            Description
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[10%]")}>
                        <Text>
                            Qty
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[8%]")}>
                        <Text>
                            UOM
                        </Text>
                    </View>
                    {/* <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[12%]")}>
                        <Text>
                            Weight
                        </Text>
                    </View> */}
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[12%]")}>
                        <Text>
                            Total
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[30%]")}>
                        <Text>
                            Document attached / Remarks
                        </Text>
                    </View>
                </View>
                {items.map((groupItem, index) =>
                    <View key={index} style={tw("w-full text-xs border-x border-b border-gray-500 text-center flex flex-row")}>
                        <View style={tw("flex flex-col border-r border-gray-500 justify-center items-center w-[10%]")}>
                            {(groupItem?.items || []).map((item, index) =>
                                <View key={index} style={tw(`flex flex-row p-1 w-full ${(groupItem?.items || []).length === (index + 1) ? "" : "border-b border-gray-500"}`)}>
                                    <Text style={tw('w-full')}>
                                        {count++}
                                    </Text>
                                </View>
                            )}
                        </View>
                        <View style={tw("flex flex-col border-r border-gray-500 justify-around items-center w-[37%]")}>
                            {(groupItem?.items || []).map((item, index) =>
                                <View key={index} style={tw(`flex flex-row w-full ${(groupItem?.items || []).length === (index + 1) ? "" : "border-b border-gray-500"} `)}>
                                    <Text style={tw('w-1/2 border-r border-gray-500')}>
                                        {item?.assy_mark}
                                    </Text>
                                    <Text style={tw('w-1/2')}>
                                        {item?.name}
                                    </Text>
                                </View>
                            )}
                        </View>
                        <View style={tw("flex flex-col border-r border-gray-500 justify-around items-center w-[10%]")}>
                            {(groupItem?.items || []).map((item, index) =>
                                <View key={index} style={tw('flex flex-row p-1  w-full')}>
                                    <Text style={tw('w-full')}>
                                        {item?.qty}
                                    </Text>
                                </View>
                            )}
                        </View>
                        <View style={tw("flex flex-col last:border-r border-gray-500 justify-around text-center items-center w-[8%]")}>
                            <Text>
                                {groupItem?.items[0]?.uom}
                            </Text>
                        </View>
                        {/* <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[12%]")}>
                            <Text>
                                {(parseFloat(isDrawingWeight ? (groupItem?.items.reduce((a, c) => a + parseFloat(c.weight), 0)) : (groupItem?.physical_weight))).toFixed(3)}
                            </Text>
                        </View> */}
                        <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[12%]")}>
                            <Text>
                                {isNaN((parseFloat(isDrawingWeight ? (groupItem?.items.reduce((a, c) => a + parseFloat(c.weight), 0)) : (groupItem?.physical_weight)) * parseFloat(groupItem?.items.reduce((a, c) => a + parseFloat(c.qty), 0))).toFixed(3))
                                    ?
                                    groupItem?.items[0]?.qty
                                    :
                                    (parseFloat(isDrawingWeight ? (groupItem?.items.reduce((a, c) => a + parseFloat(c.weight), 0)) : (groupItem?.physical_weight)) * parseFloat(groupItem?.items.reduce((a, c) => a + parseFloat(c.qty), 0))).toFixed(3)
                                }
                            </Text>
                        </View>
                        <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[30%]")}>
                            <Text>
                                {groupItem?.groupName}
                            </Text>
                        </View>
                    </View>
                )}
                {Array.from({ length: 23 }).map((i, index) =>
                    <View key={index} style={tw("w-full text-xs border-x border-b border-gray-500 text-center flex flex-row h-[20px]")}>
                        <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[10%]")}>
                            <Text>
                            </Text>
                        </View>
                        <View style={tw("flex flex-col border-r border-gray-500 justify-around items-center w-[37%]")}>
                        </View>
                        <View style={tw("flex flex-col border-r border-gray-500 justify-around items-center w-[10%]")}>
                        </View>
                        <View style={tw("flex flex-col last:border-r border-gray-500 justify-around text-center items-center w-[8%]")}>
                            <Text>
                            </Text>
                        </View>
                        <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[12%]")}>
                            <Text>
                            </Text>
                        </View>
                        <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[30%]")}>
                            <Text>
                            </Text>
                        </View>
                    </View>
                )}
                <View style={tw("w-full text-xs border-x border-b border-gray-500 text-center flex flex-row h-[20px]")}>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[10%]")}>
                        <Text>
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[37%]")}>
                        <Text>
                            Sub total
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[10%]")}>
                        <Text>
                            {totalQty}
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[8%]")}>
                        <Text>
                            {items?.[0]?.items[0]?.uom}
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[12%]")}>
                        <Text>
                            {parseFloat(totalWeight || 0).toFixed(3)}
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[30%]")}>
                        <Text>
                            Page PTO
                        </Text>
                    </View>
                </View>
                <View fixed style={tw("absolute bottom-5")}>
                    <View style={tw("text-center w-full pb-1 pt-1 px-2 text-xs ")}>
                        <Text render={({ pageNumber, totalPages }) => (
                            `Page ${pageNumber} / ${totalPages}`
                        )} fixed />
                    </View>
                </View>
            </Page>
            <Page2 totalQty={totalQty} uom={items?.[0]?.items[0]?.uom} totalWeight={totalWeight} />
        </Document>
    )
}

export default PrintFormat