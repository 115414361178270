import React from 'react'
import { getInvoiceTotal } from '../../../Utils/helper';
import { Text, View, Image } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import * as numToText from 'num-to-text';
import commaNumber from 'comma-number';
import sign from "../../../assets/cropimg.jpeg"
import { COMPANY_NAME } from '../../../Constants';

const PrintGstGrid = ({ items, isIGst }) => {
    const totalTaxValue = items.reduce((a, c) => a + parseFloat(getInvoiceTotal(c).taxValue), 0).toFixed(3);
    const totalTaxValueSplit = items.reduce((a, c) => a + parseFloat(getInvoiceTotal(c).splitValue), 0).toFixed(3);
    const totalAmount = items.reduce((a, c) => a + parseFloat(getInvoiceTotal(c).totalAmount), 0).toFixed(3);
    const taxArr = [
        {
            name: "Central Tax CGST",
            value: isIGst ? "NIL" : numToText(totalTaxValueSplit, 'in'),
            additional: "Freight",
            additionalValue: "INCLUDED",
        },
        {
            name: "State Tax SGST",
            value: isIGst ? "NIL" : numToText(totalTaxValueSplit, 'in'),
            additional: "",
            additionalValue: "",
        },
        {
            name: "Integrated Tax IGST",
            value: isIGst ? numToText(totalTaxValue, 'in') : "NIL",
            additional: "",
            additionalValue: "",
        },
        {
            name: "TCS @ 0.1%",
            value: "NIL",
            additional: "",
            additionalValue: "",
        },
        {
            name: "Total  Tax Invoice Value",
            value: numToText(totalAmount, 'in'),
            additional: "Grand Total",
            additionalValue: commaNumber(totalAmount),
        },
    ]
    return (
        <>
            <View style={tw("w-full border-b border-l border-r border-gray-500 font-bold h-[40px]")}>
                <Text style={tw("text-justify font-bold p-1 text-xs text-black")}>
                    Total Invoice Value (In Words):
                    <Text style={tw("font-[Times-Bold]")}>
                        {numToText(totalAmount, "in")} Only
                    </Text>
                </Text>
                {isIGst ?
                    <Text style={tw("text-justify font-bold p-1 text-xs text-black")}>
                        IGST (In Words):
                        <Text style={tw("font-[Times-Bold]")}>
                            {numToText(totalTaxValue, "in")} Only
                        </Text>
                    </Text>
                    :
                    <>
                        <Text style={tw("text-justify font-bold p-1 text-xs text-black")}>
                            SGST (In Words):
                            <Text style={tw("font-[Times-Bold]")}>
                                {numToText(totalTaxValueSplit, "in")} Only
                            </Text>
                        </Text>
                        <Text style={tw("text-justify font-bold p-1 text-xs text-black")}>
                            CGST (In Words):
                            <Text style={tw("font-[Times-Bold]")}>
                                {numToText(totalTaxValueSplit, "in")} Only
                            </Text>
                        </Text>
                    </>
                }
            </View>
            <View style={tw("w-full border border-gray-500 text-center flex flex-row h-[75px] font-[Times-Bold]")}>
                <View style={tw("w-4/6 border-r border-gray-500 h-full")}>
                    <View style={tw("flex flex-col h-full w-full pr-2 text-[8px]")}>
                        <Text style={tw("text-xs font-bold text-sky-900 text-left mb-1")}>
                            Declaration
                        </Text>
                        <Text style={tw("pb-3 text-gray-700 text-left")}>
                            Certified that the particulars given above are true & correct and the amounts indicated represent the prices actually charged and that directly or indirectly from the recipient
                        </Text>
                        <Text style={tw("text-gray-700 text-left")}>
                            I/we certify that our registration certificate under the GST Act, 2017 is in force on the date on which the supply of goods specified in this Tax Invoice is made by me/us & the transaction of supply covered by Tax Invoice had been effected by me/us & it shall be accounted for in the turnover of supplies while filing of return & the due tax if any payable on the supplies has been paid or shall be paid.
                        </Text>
                    </View>
                </View>
                <View style={tw("w-2/6 flex flex-col items-center justify-center border-l pr-1 border-gray-500 h-full text-[8px]")}>
                    <Text>
                        For&nbsp;{COMPANY_NAME}
                    </Text>
                    <Image style={tw("w-[100px] h-[50px] object-fit")} src={sign} />
                    <Text>
                        Authorised Signature
                    </Text>
                </View>
            </View>
        </>
    )
}

export default PrintGstGrid
