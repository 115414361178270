import React from 'react'
import { Document, Page, Text, View } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf'
import PrintHeader from './PrintHeader';
import PrintMain from './PrintMain';
import PrintGrid from './PrintGrid';
import PrintTotalGrid from './PrintTotalGrid';
import PrintGstGrid from './PrintGstGrid';


const PrintFormatInvoice = ({ data, isIGst, branchData }) => {
    const address = branchData?.address;
    const items = data?.InvoiceDetails || [];
    return (
        <Document style={tw("w-full h-full text-[10px]")} >
            <Page size="A4" style={{ fontFamily: "Times-Roman", ...tw("relative p-2 pb-[50px]") }}>
                <View fixed style={tw("w-full")}>
                    <PrintHeader address={address} />
                </View>
                <PrintMain data={data} address={address} />
                <PrintGrid items={items} isIGst={isIGst} />
                <PrintTotalGrid items={items} isIGst={isIGst} />
                <PrintGstGrid items={items} isIGst={isIGst} />
                <View fixed style={tw("absolute bottom-5")}>
                    <View style={tw("text-center w-full pb-1 pt-1 px-2 text-xs ")}>
                        <Text render={({ pageNumber, totalPages }) => (
                            `Page ${pageNumber} / ${totalPages}`
                        )} fixed />
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default PrintFormatInvoice