import React from 'react'
import { DropdownInput, MultiSelectDropdown } from '../../Inputs';
import { dropDownListMergedObject, multiSelectOption } from '../../Utils/contructObject';
import { getCommonParams } from '../../Utils/helper';
import { useGetCityQuery } from '../../redux/services/CityMasterService';

const CityDropdown = ({ multiSelect = false, withoutLabel = false, readOnly, name, selected, setSelected, disabled, id }) => {
    const params = getCommonParams();
    const { data: cityList } = useGetCityQuery({ params: { ...params } });
    const data = cityList?.data || [];
    return (
        <>
            {withoutLabel ?
                <select value={selected} onChange={(e) => setSelected(e.target.value)} className='w-full table-data-input'>
                    <option value="">Select</option>
                    {(data).map(item =>
                        <option key={item.id} value={item.id}>{item.name}</option>
                    )}
                </select>
                :
                <>
                    {multiSelect ?
                        <MultiSelectDropdown readOnly={readOnly} name={name} selected={selected} setSelected={setSelected}
                            options={multiSelectOption(data, "name", "id")} />
                        :
                        <DropdownInput name="City/State Name" options={dropDownListMergedObject(id ? data : data.filter(item => item.active), "name", "id")} value={selected} setValue={setSelected} required={true} readOnly={readOnly} disabled={disabled} />

                    }
                </>
            }
        </>
    )
}

export default CityDropdown
