import React from 'react';
import { Page, Text, View } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';

const Page2 = ({ totalQty, uom, totalWeight }) => {
    return (
        <Page size="A4" style={{ fontFamily: "Times-Roman", ...tw("relative pb-[10px] text-[10px]") }}>
            <View style={tw("h-[30%] p-1")}>
                <View style={tw("w-full text-xs border-x border-b border-gray-500 text-center flex flex-row h-[20px]")}>
                    <View style={tw("flex flex-row  border-r border-gray-500 justify-center items-center w-[10%]")}>
                        <Text>
                            S.No
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[37%]")}>
                        <Text>
                            Description
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[10%]")}>
                        <Text>
                            Qty
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[8%]")}>
                        <Text>
                            UOM
                        </Text>
                    </View>
                    {/* <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[12%]")}>
                        <Text>
                            Weight
                        </Text>
                    </View> */}
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[12%]")}>
                        <Text>
                            Total
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[30%]")}>
                        <Text>
                            Document attached / Remarks
                        </Text>
                    </View>
                </View>
                <View style={tw("w-full text-xs border-x border-b border-gray-500 text-center flex flex-row h-[20px]")}>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[47%]")}>
                        <Text>
                            Carry Forward from Previous Page
                        </Text>
                    </View>
                    <View style={tw("flex flex-col border-r border-gray-500 justify-around items-center w-[10%]")}>
                        <Text>
                            {totalQty}
                        </Text>
                    </View>
                    <View style={tw("flex flex-col last:border-r border-gray-500 justify-around text-center items-center w-[8%]")}>
                        <Text>
                            {uom}
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[12%]")}>
                        <Text>
                            {totalWeight}
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[30%]")}>
                        <Text>
                        </Text>
                    </View>
                </View>
                {Array.from({ length: 12 }).map((i, index) =>
                    <View key={index} style={tw("w-full text-xs border-x border-b border-gray-500 text-center flex flex-row h-[20px]")}>
                        <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[10%]")}>
                            <Text>
                            </Text>
                        </View>
                        <View style={tw("flex flex-col border-r border-gray-500 justify-around items-center w-[37%]")}>
                        </View>
                        <View style={tw("flex flex-col border-r border-gray-500 justify-around items-center w-[10%]")}>
                        </View>
                        <View style={tw("flex flex-col last:border-r border-gray-500 justify-around text-center items-center w-[8%]")}>
                            <Text>
                            </Text>
                        </View>
                        <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[12%]")}>
                            <Text>
                            </Text>
                        </View>
                        <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[30%]")}>
                            <Text>
                            </Text>
                        </View>
                    </View>
                )}
                <View style={tw("w-full text-xs border-x border-b border-gray-500 text-center flex flex-row h-[20px]")}>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[47%]")}>
                        <Text>
                            Total Qty and Weight
                        </Text>
                    </View>
                    <View style={tw("flex flex-col border-r border-gray-500 justify-around items-center w-[10%]")}>
                        <Text>
                            {totalQty}
                        </Text>
                    </View>
                    <View style={tw("flex flex-col last:border-r border-gray-500 justify-around text-center items-center w-[8%]")}>
                        <Text>
                            {uom}
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[12%]")}>
                        <Text>
                            {totalWeight}
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[30%]")}>
                        <Text>
                        </Text>
                    </View>
                </View>
            </View>
            <View style={tw("h-[70%]")}>
                <Text style={{ ...tw("text-center text-2xl font-[Times-Bold] py-1"), fontSize: 12 }}>CUSTOMER ACKNOWLEDGEMENT</Text>
                <View style={tw("h-full")}>
                    <View fixed style={tw("w-full p-1")}>
                        <View style={tw("w-full h-32 flex flex-row border-x border-t border-gray-500 items-left")}>
                            <View style={[tw("w-1/4 align-center border-r border-gray-500"), { justifyContent: 'center', alignItems: 'center' }]}>
                                <Text style={{ ...tw("font-bold"), fontSize: 11, textAlign: 'center' }}>Shortage Report</Text>
                            </View>

                            <View style={tw("flex-1 border-gray-500")}>
                                <View style={tw("flex flex-col h-full")}>
                                    <View style={tw("h-1/3 border-b border-gray-500")}></View>
                                    <View style={tw("h-1/3 border-b border-gray-500")}></View>
                                    <View style={tw("h-1/3  border-gray-500")}></View>
                                </View>
                            </View>
                        </View>


                        <View style={tw("w-full h-32 flex flex-row border-x border-t border-gray-500 items-left")}>
                            <View style={[tw("w-1/4 align-center border-r border-gray-500"), { justifyContent: 'center', alignItems: 'center' }]}>
                                <Text style={{ ...tw("font-bold"), fontSize: 11, textAlign: 'center' }}>Damage Report</Text>
                            </View>

                            <View style={tw("flex-1  border-gray-500")}>
                                <View style={tw("flex flex-col h-full")}>
                                    <View style={tw("h-1/3 border-b border-gray-500")}></View>
                                    <View style={tw("h-1/3 border-b border-gray-500")}></View>
                                    <View style={tw("h-1/3  border-gray-500")}></View>
                                </View>
                            </View>
                        </View>


                        <View style={tw("w-full h-32 flex flex-row border-x border-t border-gray-500 items-left")}>
                            <View style={[tw("w-1/4 align-center border-r border-gray-500"), { justifyContent: 'center', alignItems: 'center' }]}>
                                <Text style={{ ...tw("font-bold"), fontSize: 11, textAlign: 'center' }}>General Remarks</Text>
                            </View>

                            <View style={tw("flex-1  border-gray-500")}>
                                <View style={tw("flex flex-col h-full")}>
                                    <View style={tw("h-1/3 border-b border-gray-500")}></View>
                                    <View style={tw("h-1/3 border-b border-gray-500")}></View>
                                    <View style={tw("h-1/3  border-gray-500")}></View>
                                </View>
                            </View>
                        </View>



                        <View style={tw("w-full h-32 flex flex-row border-x border-t border-gray-500 items-center")}>
                            <View style={[tw("w-1/4 justify-center border-r h-full border-gray-500"), { alignItems: 'center' }]}>
                                <Text style={{ ...tw("font-bold text-center"), fontSize: 11 }}>RECEIVED BY</Text>
                            </View>

                            <View style={tw("flex-1 border-gray-500 w-full")}>
                                <View style={tw("flex flex-col h-full w-full")}>
                                    <View style={tw("h-2/3 w-full   flex flex-row border-gray-500")}>
                                        <View style={tw("w-1/2 border-r border-gray-500 ")}></View>
                                        <View style={tw("w-1/2 border-gray-500 ")}></View>

                                    </View>
                                    <View style={tw("h-1/3 flex flex-row w-full  ")}>
                                        <View style={tw("w-1/2 border-r border-gray-500 pt-3")}><Text style={{ ...tw("font-bold text-center"), fontSize: 11 }}>Name and Signature </Text></View>
                                        <View style={tw("w-1/2 border-gray-500 pt-3")}><Text style={{ ...tw("font-bold text-center"), fontSize: 11 }}>Position & Date</Text></View>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={tw("w-full flex flex-row border-x border-t border-gray-500 h-16")}>
                            <View style={tw("w-1/4 p-2 border-r border-b border-gray-500")}>
                                <Text style={{ ...tw("font-bold"), fontSize: 11 }}>Note</Text>
                            </View>
                            <View style={tw("flex-1 p-2 border-b border-gray-500")}></View>
                        </View>
                        <View style={tw("border-x border-b border-gray-500")}>
                            <View style={tw("border-gray-500 p-3 border-b")}>
                                <Text style={{ ...tw("font-bold"), fontSize: 11 }}>
                                    * <Text style={{ fontSize: 12 }}>Shortage of Materials</Text> (items that are mentioned in packing list but not found in the trailer){'\n'}
                                    * <Text style={{ fontSize: 12 }}>Damaged Materials</Text> A digital Photo is required to be taken of damaged material prior to unloading it from the trailer
                                </Text>
                            </View>
                            <Text style={{ ...tw("font-bold text-[10px] p-1") }}>
                                Two Copies of the delivery note are sent with the Driver. Reporting of Shortages & Damages must be note on each copy of the delivery Note and Signed prior to the departure of the empty trailer from the jobsite. One copy of the Delivery note should be handed to the Representative of Hansem Building solutions/Erection Department, Small items (Bolts,Fasteners,Bead mastic. etc.,) that are in boxes should be checked and any shortages or damages must be reported within 7 working days.
                            </Text>
                        </View>
                    </View>
                </View>
            </View>
            <View fixed style={tw("absolute bottom-5")}>
                <View style={tw("text-center w-full pb-1 pt-1 px-2 text-xs ")}>
                    <Text render={({ pageNumber, totalPages }) => (
                        `Page ${pageNumber} / ${totalPages}`
                    )} fixed />
                </View>
            </View>
        </Page>
    );
};

export default Page2;
