import React, { useEffect, useState, useRef, useCallback } from "react";
import {
    useGetProjectByIdQuery,
    useAddProjectMutation,
    useUpdateProjectMutation,
    useDeleteProjectMutation,
} from "../../../redux/Construction/ProjectServices";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices";
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { CheckBox, DropdownInput, TextArea, TextInput } from "../../../Inputs";
import { dropDownListObject, } from '../../../Utils/contructObject';

import Modal from "../../../UiComponents/Modal";
import FormReport from "./FormReport";

import { getCommonParams } from "../../../Utils/helper";
import CityDropdown from "../../../Basic/ReusableComponents/CityDropdown";

const MODEL = "Project Master";

export default function Form() {
    const [readOnly, setReadOnly] = useState(false);
    const [id, setId] = useState("");
    const [formReport, setFormReport] = useState(false);
    const [partyId, setPartyId] = useState("");
    const [name, setName] = useState("");
    const [jobNo, setJobNo] = useState("");
    const [jobLocation, setJobLocation] = useState("");
    const [workSiteAddress, setWorkSiteAddress] = useState("")
    const [isDrawingWeight, setIsDrawingWeight] = useState(true);
    const [pincode, setPincode] = useState("");
    const [cityId, setCityId] = useState("");
    const [area, setArea] = useState("");

    const childRecord = useRef(0);

    const { branchId, companyId, finYearId, userId } = getCommonParams()

    const params = {
        branchId, companyId, finYearId
    };

    const { data: supplierList } =
        useGetPartyQuery({ params: { ...params } });


    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetProjectByIdQuery(id, { skip: !id });

    const [addData] = useAddProjectMutation();
    const [updateData] = useUpdateProjectMutation();
    const [removeData] = useDeleteProjectMutation();

    const syncFormWithDb = useCallback((data) => {
        if (id) {
            setReadOnly(true);
        } else {
            setReadOnly(false);
        }
        setPartyId(data?.partyId ? data?.partyId : "");
        setName(data?.name || "");
        setIsDrawingWeight(id ? (data?.isDrawingWeight ? data.isDrawingWeight : false) : true);
        setJobNo(data?.jobNo || "");
        setJobLocation(data?.location || "");
        setWorkSiteAddress(data?.workSiteAddress || "");
        setPincode(data?.pincode || '');
        setCityId(data?.cityId || '');
        setArea(data?.area || '');
    }, [id]);



    useEffect(() => {
        if (id) {
            syncFormWithDb(singleData?.data);
        } else {
            syncFormWithDb(undefined);
        }
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    const data = {
        branchId, id, userId,
        finYearId,
        name,
        partyId,
        isDrawingWeight,
        location: jobLocation,
        jobNo,
        workSiteAddress,
        pincode,
        cityId,
        area
    }


    const validateData = (data) => {
        return data.name && data.partyId && data.pincode
            && data.cityId
            && data.area
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData;
            if (text === "Updated") {
                returnData = await callback(data).unwrap();
            } else {
                returnData = await callback(data).unwrap();
            }
            if (returnData.statusCode === 1) {
                toast.error(returnData.message);
                return
            } else {
                onNew()
                toast.success(text + "Successfully");
            }
        } catch (error) {
            console.log("handle");
        }
    };


    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", { position: "top-center" })
            return
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    }

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                onNew();
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setReadOnly(false);
        syncFormWithDb(undefined)
    };


    const tableHeadings = ["PoNo", "PoDate", "transType", "DueDate", "Supplier"]
    const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']

    let supplierListBasedOnSupply = supplierList ? supplierList.data : []
    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto">
            <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
                <FormReport
                    heading={MODEL}
                    tableHeaders={tableHeadings}
                    tableDataNames={tableDataNames}
                    tableWidth="100%"
                    onClick={(id) => {
                        setId(id);
                        setFormReport(false);
                    }
                    }
                />
            </Modal>
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}
                    openReport={() => { setFormReport(true) }}
                    childRecord={childRecord.current}
                />
                <div className="flex-1 grid gap-x-2">
                    <div className="col-span-3 grid ">
                        <div className='col-span-3 grid '>
                            <div className='mr-1'>
                                <div className={`grid`}>
                                    <div className={"flex flex-col"}>
                                        <fieldset className='frame rounded-tr-lg rounded-bl-lg w-full border border-gray-600 px-3 overflow-auto h-[100px]'>
                                            <legend className='sub-heading'>Info</legend>
                                            <div className='flex flex-col justify-center items-start flex-1 w-full'>
                                                <div className="grid grid-cols-5 w-full">
                                                    <TextInput name={"Name"} value={name} setValue={setName} required readOnly={readOnly} />
                                                    <DropdownInput name="Customer" options={dropDownListObject(supplierListBasedOnSupply, "aliasName", "id")} value={partyId} setValue={setPartyId} required={true} readOnly={id} />
                                                    <TextInput name={"Job No."} value={jobNo} setValue={setJobNo} required readOnly={readOnly} />
                                                    <TextInput name={"Location"} value={jobLocation} setValue={setJobLocation} required readOnly={readOnly} />
                                                    <TextInput name={"Pincode"} value={pincode} setValue={setPincode} required readOnly={readOnly} />
                                                    <TextArea name={"Address"} value={workSiteAddress} setValue={setWorkSiteAddress} required readOnly={readOnly} />
                                                    <TextInput name={"Area"} value={area} setValue={setArea} required readOnly={readOnly} />
                                                    <CityDropdown name={"City"} readOnly={readOnly} disabled={childRecord.current > 0} setSelected={setCityId} selected={cityId} />
                                                    <CheckBox name={"Drawing Weight"} value={isDrawingWeight} setValue={setIsDrawingWeight} required={true} readOnly={readOnly} />
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}