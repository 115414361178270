import React, { useState } from 'react'
import { useUpdateInvoiceMutation } from '../../../redux/Construction/InvoiceServices';
import { TextInput } from '../../../Inputs';
import { GenerateButton } from '../../../Buttons';
import { toast } from 'react-toastify';

const EInvoice = ({ id, onClose, irn, ewayBillNo, transporterId }) => {
    const [transId, setTransId] = useState("");
    const [transName, setTransName] = useState("");
    const [transRefNo, setTransRefNo] = useState("");
    const [currentSelection, setCurrentSelection] = useState("E-Invoice")
    const [vehicleNo, setVehicleNo] = useState('');

    const [updateData] = useUpdateInvoiceMutation();

    let options = [
        { name: "E-Invoice" },
        { name: "E-Invoice With E-way Bill" },
        { name: "E-Invoice With E-way Bill With Part A" },
    ]

    if (irn && (!ewayBillNo)) {
        options = [{
            name: "E-Way Bill By Irn"
        }]
    }
    if (irn && (ewayBillNo) && !transporterId) {
        options = [{
            name: "E-Invoice With E-way Bill With Part A"
        }]
    } else if (irn && (ewayBillNo)) {
        options = [{
            name: "Update Vehicle No"
        }]
    }
    // options = [{ name: "Update Vehicle No" }]

    async function handleSubmit() {
        let data;
        if (currentSelection === "E-Invoice") {
            data = { id, isGenerateEInvoice: true }
        } else if (currentSelection === "E-Invoice With E-way Bill") {
            data = { id, isGenerateEInvoice: true, isGenerateEInvoiceWithEwayBill: true }
        } else if (currentSelection === "E-Invoice With E-way Bill With Part A") {
            if (!transId) return toast.info("Transporter Id Required...!!!")
            if (!transName) return toast.info("Transporter Name Required...!!!")
            if (!transRefNo) return toast.info("Transporter Doc No Required...!!!")
            data = { id, isGenerateEInvoice: true, isGenerateEInvoiceWithEwayBill: true, isPartA: true }
        } else if (currentSelection === "E-Way Bill By Irn") {
            data = { id, isGenerateEwayBillByIRN: true }
        } else if (currentSelection === "Update Vehicle No") {
            data = { id, isVehicleNoUpdate: true, vehicleNo }
        }
        const returnData = await updateData(data).unwrap();
        if (returnData.statusCode === 1) {
            toast.error(returnData.message);
            return
        } else {
            toast.success("EInvoice Successfully");
            onClose()
        }
    }
    return (
        <div className='bg-gray-200 w-full h-full border-gray-500 border'>
            <div className='text-center'>E-Invoice</div>
            <table className='w-full'>
                <thead className='table-head'>
                    <th className='table-head'>
                        Select
                    </th>
                    <th className='table-head'>
                        Type
                    </th>
                </thead>
                <tbody className='table-head'>
                    {options.map(i =>
                        <tr className='table-row text-center'>
                            <td className='table-data'>
                                <input type="radio" checked={currentSelection === i.name} onChange={() => setCurrentSelection(i.name)} />
                            </td>
                            <td className='table-data'>
                                {i.name}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            {currentSelection === "E-Invoice With E-way Bill With Part A"
                &&
                <div>
                    <TextInput name={"Transporter Id"} value={transId} setValue={setTransId} />
                    <TextInput name={"Transporter Name"} value={transName} setValue={setTransName} />
                    <TextInput name={"Transporter Doc No"} value={transRefNo} setValue={setTransRefNo} />
                </div>
            }
            {currentSelection === "Update Vehicle No"
                &&
                <div>
                    <TextInput name={"Vehicle No"} value={vehicleNo} setValue={setVehicleNo} />
                </div>
            }
            <div className='flex justify-end'>
                <GenerateButton name={"Submit"} onClick={handleSubmit} />
            </div>
        </div>
    )
}

export default EInvoice
