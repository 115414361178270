import React from 'react'
import { DELETE, PLUS } from '../../../icons'

const DeliveryDetails = ({ groupedDeliveryDetails, setGroupedDeliveryDetails, setDeliveryDetails, onOpen, isDrawingWeight, readOnly }) => {
    const removeItem = (item) => {
        setDeliveryDetails(prev => prev.filter(i => parseInt(i.projectPhaseDetailsId) !== parseInt(item.projectPhaseDetailsId)))
    }
    const handleInputChange = (value, index, field) => {
        setGroupedDeliveryDetails(prev => {
            const newBlend = structuredClone(prev);
            newBlend[index][field] = value;
            return newBlend
        });
    };
    const handleGroupedChange = (value, index, subIndex, field) => {
        setGroupedDeliveryDetails(prev => {
            const newBlend = structuredClone(prev);
            newBlend[index]["items"][subIndex][field] = value;
            return newBlend
        });
    };
    const handleGroupedChangeCommon = (value, index, field) => {
        setGroupedDeliveryDetails(prev => {
            const newBlend = structuredClone(prev);
            if (field === "uom") {
                newBlend[index][field] = value;
            }
            newBlend[index]["items"] = newBlend[index]["items"].map(i => ({ ...i, [field]: value }));
            console.log(newBlend, 'newBlend');
            return newBlend
        });
    };
    return (
        <div className="w-full">
            <div className="w-full flex flex-col gap-5">
                <div className="mt-3 flex flex-col justify-start items-start gap-10">
                    <table className="w-full">
                        <thead>
                            <tr className='h-[30px]'>
                                <th className="border border-gray-400 text-sm  w-10">S.No</th>
                                <th className='border-x border-t border-gray-400 text-sm h-[30px]' colSpan={2}>
                                    <div className='h-full'>
                                        <div className='h-1/2'>
                                            Description
                                        </div>
                                        <div className='h-1/2 grid grid-cols-2 items-center justify-center w-full border-t border-gray-500'>
                                            <span className='border-r border-gray-500'>Assy Mark</span>
                                            <span className=''>Column</span>
                                        </div>
                                    </div>
                                </th>
                                <th className='border border-gray-400 text-sm  w-20'>
                                    Qty
                                </th>
                                <th className='border border-gray-400 text-sm  w-20'>
                                    Draw. Weight
                                </th>
                                <th className='border border-gray-400 text-sm  w-20'>
                                    Phy. Weight
                                </th>
                                {!readOnly &&
                                    <th className="border border-gray-400  text-sm  text-white " onClick={onOpen}>
                                        <div className='w-full h-full  grid items-center justify-center'>
                                            <div className='w-full text-green-700'>
                                                {PLUS}
                                            </div>
                                        </div>
                                    </th>
                                }
                                <th className='border border-gray-400 text-sm  w-20'>
                                    Total Weight
                                </th>
                                <th className='border border-gray-400 text-sm  w-20'>
                                    UOM
                                </th>
                                <th className='border border-gray-400 text-sm  w-32'>
                                    Documents attached / Remarks
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {groupedDeliveryDetails.map((row, rowIndex) => (
                                <tr key={rowIndex} className='text-sm h-[30px]'>
                                    <td className="border border-gray-400 text-sm  text-center">{rowIndex + 1}</td>
                                    <td className=' border border-gray-400 text-sm text-center' colSpan={2}>
                                        {(row?.items || []).map((i, index) =>
                                            <div key={index} className='grid grid-cols-2 w-full items-center justify-center border-b border-gray-500 last:border-b-0'>
                                                <span className='border-r border-gray-500'>{i?.assy_mark}</span>
                                                <span>{i?.name}</span>
                                            </div>
                                        )}
                                    </td>
                                    <td className=' border border-gray-400 text-sm  text-center'>
                                        <div className='w-full flex flex-col items-center justify-center'>
                                            {(row?.items || []).map((i, index) =>
                                                <input type="text" key={index} value={i?.qty} className='w-full  text-center border-b border-gray-500 h-full last:border-0' onChange={(e) => handleGroupedChange(e.target.value, rowIndex, index, "qty")} disabled={readOnly} />
                                            )}
                                        </div>
                                    </td>
                                    <td className="border border-gray-400 text-sm "
                                    >
                                        <div className='grid w-full'>
                                            {(row?.items || []).map((i, index) =>
                                                <input disabled type="text" key={index} value={i?.weight} className='w-full  text-center border-b border-gray-500 h-full last:border-0' onChange={(e) => handleGroupedChange(e.target.value, rowIndex, index, "weight")} />
                                            )}
                                        </div>
                                    </td>
                                    <td className=' table-data text-right border border-gray-400 text-sm '>
                                        <input type="text" value={row?.physical_weight} className='w-full text-right pr-1' onChange={(e) => handleInputChange(e.target.value, rowIndex, "physical_weight")} disabled={readOnly} />
                                    </td>
                                    {!readOnly &&
                                        <td className="border border-gray-400 text-sm">
                                            <div className='grid h-full items-center'>
                                                {(row?.items || []).map((i, index) =>
                                                    <div className='last:border-b-0 border-b border-gray-500 flex items-center justify-center h-full' >
                                                        <button key={index} onClick={() => removeItem(i)} className={` `}>
                                                            {DELETE}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                    }
                                    <td className=' table-data text-right border border-gray-400 text-sm '>

                                        {isNaN((parseFloat(isDrawingWeight ? (row?.items.reduce((a, c) => a + parseFloat(c.weight), 0)) : (row?.physical_weight)) * parseFloat(row?.items.reduce((a, c) => a + parseFloat(c.qty), 0))).toFixed(3))
                                            ?
                                            row?.items[0]?.qty
                                            :
                                            (parseFloat(isDrawingWeight ? (row?.items.reduce((a, c) => a + parseFloat(c.weight), 0)) : (row?.physical_weight)) * parseFloat(row?.items.reduce((a, c) => a + parseFloat(c.qty), 0))).toFixed(3)
                                        }
                                    </td>
                                    <td className="border border-gray-400 text-sm"
                                    >
                                        <div className='grid w-full text-center '>
                                            <input type="text" value={row?.uom} className='w-full text-right pr-1' onChange={(e) => handleGroupedChangeCommon(e.target.value, rowIndex, "uom")} disabled={readOnly} />
                                        </div>
                                    </td>
                                    <td className=' table-data text-right border border-gray-400 text-sm '>
                                        {row?.groupName}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default DeliveryDetails