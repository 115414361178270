import React, { useMemo } from 'react'
import { useEffect, useState } from "react";
import { Loader } from "../../../Basic/components";
import { getCommonParams } from "../../../Utils/helper";
import { showEntries } from '../../../Utils/DropdownData';
import secureLocalStorage from 'react-secure-storage';
import { pageNumberToReactPaginateIndex, reactPaginateIndexToPageNumber } from '../../../Utils/helper';
import ReactPaginate from 'react-paginate';
import { useGetInvoiceQuery } from '../../../redux/Construction/InvoiceServices';
import PartyDropdown from '../../../ERP/ReusableComponents/PartyDropdown';


const FoxConnStatement = () => {
    const branchId = secureLocalStorage.getItem(
        sessionStorage.getItem("sessionId") + "currentBranchId"
    )
    const [searchDocId, setSearchDocId] = useState("");
    const [searchDocDate, setSearchDocDate] = useState("");
    const [dataPerPage, setDataPerPage] = useState("10");
    const [totalCount, setTotalCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [partyId, setPartyId] = useState("");
    const handleOnclick = (e) => {
        setCurrentPageNumber(reactPaginateIndexToPageNumber(e.selected));
    }
    const searchFields = useMemo(() => ({ searchDocId, searchDocDate, partyId }), [searchDocId, searchDocDate, partyId])

    useEffect(() => { setCurrentPageNumber(1) }, [searchFields])

    const { finYearId } = getCommonParams()

    const { data: allData, isLoading, isFetching } = useGetInvoiceQuery({ params: { branchId, ...searchFields, pagination: true, dataPerPage, pageNumber: currentPageNumber, finYearId, invoiceReport: true, partyId } });


    useEffect(() => {
        if (allData?.totalCount) {
            setTotalCount(allData?.totalCount)
        }
    }, [allData, isLoading, isFetching])


    const isLoadingIndicator = isLoading || isFetching


    return (
        <div className="flex flex-col w-full h-[95%] overflow-auto" >
            <div className="md:flex md:items-center md:justify-between page-heading p-1">
                <div className="heading text-center md:mx-10">FOXCONN STATEMENT</div>
                <PartyDropdown selectName="All" multiSelect={false} withoutLabel={false} name={"Party"} selected={partyId} setSelected={setPartyId} clear />
                <div className=" sub-heading justify-center md:justify-start items-center">
                    <label className="text-white text-sm rounded-md m-1  border-none">Show Entries</label>
                    <select value={dataPerPage}
                        onChange={(e) => setDataPerPage(e.target.value)} className='h-6 w-40 border border-gray-500 rounded mr-9'>
                        {showEntries.map((option) => <option value={option.value} >{option.show}</option>)}
                    </select>
                </div>
            </div>
            <>
                <div
                    className="h-[500px] w-full overflow-auto"
                >
                    <table className="table-auto text-center w-full">
                        <thead className="border-2 table-header ">
                            <tr className='h-2'>
                                <th
                                    className="border-2  top-0 stick-bg">
                                    S. no.
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg whitespace-nowrap"
                                >
                                    <label>Material</label>
                                    {/* <input
                                        type="text"
                                        className="text-black h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                                        placeholder="Search"
                                        value={searchDocDate}
                                        onChange={(e) => {
                                            setSearchDocDate(e.target.value);
                                        }}
                                    /> */}
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg"
                                >
                                    <label className='whitespace-nowrap'>Date</label><input
                                        type="text"
                                        className="text-black  h-6 focus:outline-none border md:ml-3 border-gray-400 w-[70%] rounded-lg"
                                        placeholder="Search"
                                        value={searchDocDate}
                                        onChange={(e) => {
                                            setSearchDocDate(e.target.value);
                                        }}
                                    />
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg "
                                >
                                    <label className='whitespace-nowrap'>Inv.No</label><input
                                        type="text"
                                        className="text-black w-[70%] h-6 focus:outline-none border md:ml-3 border-gray-400 rounded-lg"
                                        placeholder="Search"
                                        value={searchDocId}
                                        onChange={(e) => {
                                            setSearchDocId(e.target.value);
                                        }}
                                    />
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg whitespace-nowrap"
                                >
                                    <label>Description</label>
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg whitespace-nowrap"
                                >
                                    <label>UOM</label>
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg whitespace-nowrap"
                                >
                                    <label>Qty</label>
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg whitespace-nowrap"
                                >
                                    <label>Rate</label>
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg whitespace-nowrap"
                                >
                                    <label>Taxable Value</label>
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg whitespace-nowrap"
                                >
                                    <label>GST %</label>
                                </th>
                                <th
                                    className="border-2  top-0 stick-bg whitespace-nowrap"
                                >
                                    <label>Total Value</label>
                                </th>
                                <th className="border-2  top-0 stick-bg whitespace-nowrap">
                                    <label>Delivery Note</label>
                                </th>
                                <th className="border-2  top-0 stick-bg whitespace-nowrap">
                                    <label>Drawing Weight</label>
                                </th>
                                <th className="border-2  top-0 stick-bg whitespace-nowrap">
                                    <label>Vehicle No.</label>
                                </th>
                                <th className="border-2  top-0 stick-bg whitespace-nowrap">
                                    <label>Eway Bill No</label>
                                </th>
                            </tr>
                        </thead>
                        {isLoadingIndicator ?
                            <tbody>
                                <tr>
                                    <td>
                                        <Loader />
                                    </td>
                                </tr>
                            </tbody>
                            :
                            <tbody className="border-2">
                                {allData.data.map((dataObj, index) => (
                                    <tr
                                        key={dataObj.id}
                                        className="border-2 table-row "
                                    >
                                        <td className='py-1 whitespace-nowrap'> {(index + 1) + (dataPerPage * (currentPageNumber - 1))}</td>
                                        <td className='py-1 whitespace-nowrap'> {
                                            (dataObj?.materials || []).map(i =>
                                                <div key={i.name}>
                                                    {i.name}
                                                </div>
                                            )}</td>
                                        <td className='py-1 whitespace-nowrap'>{dataObj?.docDate} </td>
                                        <td className='py-1 whitespace-nowrap'> {dataObj.docId}</td>
                                        <td className='py-1 whitespace-nowrap'> </td>
                                        <td className='py-1 whitespace-nowrap'> {
                                            (dataObj?.materials || []).map(i =>
                                                <div key={i.name}>
                                                    {i.uom}
                                                </div>
                                            )}</td>
                                        <td className='py-1 whitespace-nowrap'> {
                                            (dataObj?.materials || []).map(i =>
                                                <div key={i.name} className='text-right'>
                                                    {i.qty}
                                                </div>
                                            )}</td>
                                        <td className='py-1 whitespace-nowrap'> {
                                            (dataObj?.materials || []).map(i =>
                                                <div key={i.name} className='text-right'>
                                                    {i.rate}
                                                </div>
                                            )}</td>
                                        <td className='py-1 whitespace-nowrap'> {
                                            (dataObj?.materials || []).map(i =>
                                                <div key={i.name} className='text-right'>
                                                    {i.taxableValue}
                                                </div>
                                            )}</td>
                                        <td className='py-1 whitespace-nowrap'> {
                                            (dataObj?.materials || []).map(i =>
                                                <div key={i.name} className='text-right'>
                                                    {i.tax}%
                                                </div>
                                            )}</td>

                                        <td className='py-1 whitespace-nowrap text-right'>{dataObj?.materials.reduce((a, c) => a + parseFloat(c.totalValue), 0)}</td>
                                        <td className='py-1 whitespace-nowrap'>{dataObj?.deliveryNoteNo}</td>
                                        <td className='py-1 whitespace-nowrap'> {
                                            (dataObj?.materials || []).map(i =>
                                                <div key={i.name} className='text-right'>
                                                    {i?.drawingWeight}
                                                </div>
                                            )}</td>
                                        <td className='py-1 whitespace-nowrap'> {
                                            dataObj?.vehicleNo
                                        }</td>
                                        <td className='py-1 whitespace-nowrap'> {
                                            dataObj?.ewayBillNo
                                        }</td>
                                    </tr>
                                ))}
                            </tbody>
                        }
                    </table>
                </div>
            </>
            <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                forcePage={pageNumberToReactPaginateIndex(currentPageNumber)}
                pageCount={Math.ceil(totalCount / dataPerPage)}
                marginPagesDisplayed={1}
                onPageChange={handleOnclick}
                containerClassName={"flex justify-center m-2 gap-5 items-center"}
                pageClassName={"border custom-circle text-center"}
                disabledClassName={"p-1 bg-gray-200"}
                previousLinkClassName={"border p-1 text-center"}
                nextLinkClassName={"border p-1"}
                activeClassName={"bg-blue-900 text-white px-2"} />
        </div>
    )
}

export default FoxConnStatement