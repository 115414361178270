import React from 'react'
import { priceWithTax } from '../../../Utils/helper';

const InvoiceDetails = ({ invoiceDetails, setInvoiceDetails }) => {

    const handleInputChange = (value, index, field) => {
        setInvoiceDetails(prev => {
            const newBlend = structuredClone(prev);
            newBlend[index][field] = value;
            return newBlend
        });
    };
    return (
        <div className="w-full">
            <div className="w-full flex flex-col gap-5">
                <div className="mt-3 flex flex-col justify-start items-start gap-10">

                    <table className="w-full">
                        <thead>
                            <tr>
                                <th className="border border-gray-400 text-sm py-1 w-10">S.No</th>
                                <th className='border border-gray-400 text-sm py-1 w-36'>
                                    Description of Goods
                                </th>
                                <th className='border border-gray-400 text-sm py-1 w-36'>
                                    HSN / SAC CODE
                                </th>
                                <th className='border border-gray-400 text-sm py-1 w-20' colSpan={3}>
                                    <div className='grid'>
                                        <div>Identification Marks</div>
                                        <div>&</div>
                                        <div>Sr.No. of the goods</div>
                                        <div className='grid grid-cols-3 border-t border-gray-500'>
                                            <div className='border-r border-gray-500'>QTY</div>
                                            <div className='border-r border-gray-500'>UOM</div>
                                            <div>RATE</div>
                                        </div>
                                    </div>
                                </th>
                                <th className='border border-gray-400 text-sm py-1 w-28'>
                                    Taxable Value
                                </th>
                                <th className='border border-gray-400 text-sm py-1 w-10'>
                                    Tax
                                </th>
                                <th className='border border-gray-400 text-sm py-1 w-28'>
                                    Total Amount
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoiceDetails.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td className="border border-gray-400 text-sm py-1 text-center">{rowIndex + 1}</td>
                                    <td className=' table-data border border-gray-400 text-sm py-1 text-center'>
                                        {row?.groupName}
                                    </td>
                                    <td className=' table-data  border border-gray-400 text-sm py-1 text-center'>
                                        <input type="text" value={row?.hsn} className='w-full text-right' onChange={(e) => handleInputChange(e.target.value, rowIndex, "hsn")} />
                                    </td>
                                    <td className=' table-data  border border-gray-400 text-sm text-center' colSpan={3}>
                                        <div className='grid grid-cols-3'>
                                            <div className=' border-r border-gray-400'>
                                                <input type="text" value={row?.qty} className='w-full text-right' onChange={(e) => handleInputChange(e.target.value, rowIndex, "qty")} />
                                            </div>
                                            <div className=' border-r border-gray-400'>
                                                <input type="text" value={row?.uom} className='w-full text-right' onChange={(e) => handleInputChange(e.target.value, rowIndex, "uom")} />
                                            </div>
                                            <div >
                                                <input type="text" value={row?.rate} className='w-full text-right' onChange={(e) => handleInputChange(e.target.value, rowIndex, "rate")} />
                                            </div>
                                        </div>
                                    </td>
                                    <td className=' table-data text-right border border-gray-400 text-sm py-1'>
                                        {isNaN(parseFloat(row?.rate || 0) * parseFloat(row?.qty || 0)) ? parseFloat(row?.rate || 0) : parseFloat(row?.rate || 0) * parseFloat(row?.qty || 0)}
                                    </td>
                                    <td className=' table-data text-right border border-gray-400 text-sm py-1'>
                                        <input type="text" value={row?.tax} className='w-full text-right' onChange={(e) => handleInputChange(e.target.value, rowIndex, "tax")} />
                                    </td>
                                    <td className=' table-data text-right border border-gray-400 text-sm py-1'>
                                        {priceWithTax(isNaN(parseFloat(row?.rate || 0) * parseFloat(row?.qty || 0)) ? parseFloat(row?.rate || 0) : parseFloat(row?.rate || 0) * parseFloat(row?.qty || 0), row?.tax || 0).toFixed(2)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default InvoiceDetails