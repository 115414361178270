import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery"; import { CURRENCY_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const ProcessGroupMasterApi = createApi({
  reducerPath: "processGroupMaster",
  baseQuery,
  tagTypes: ["ProcessGroupMaster"],
  endpoints: (builder) => ({
    getProcessGroupMaster: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: CURRENCY_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: CURRENCY_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["ProcessGroupMaster"],
    }),
    getProcessGroupMasterById: builder.query({
      query: (id) => {
        return {
          url: `${CURRENCY_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["ProcessGroupMaster"],
    }),
    addProcessGroupMaster: builder.mutation({
      query: (payload) => ({
        url: CURRENCY_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["ProcessGroupMaster"],
    }),
    updateProcessGroupMaster: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${CURRENCY_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["ProcessGroupMaster"],
    }),
    deleteProcessGroupMaster: builder.mutation({
      query: (id) => ({
        url: `${CURRENCY_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProcessGroupMaster"],
    }),
  }),
});

export const {
  useGetProcessGroupMasterQuery,
  useGetProcessGroupMasterByIdQuery,
  useAddProcessGroupMasterMutation,
  useUpdateProcessGroupMasterMutation,
  useDeleteProcessGroupMasterMutation,
} = ProcessGroupMasterApi;

export default ProcessGroupMasterApi;
