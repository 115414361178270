import { configureStore } from "@reduxjs/toolkit";
import { openTabs } from "./features";
import {
  countryMasterApi, pageMasterApi, stateMasterApi,
  cityMasterApi, departmentMasterApi, employeeCategoryMasterApi,
  finYearMasterApi, rolesMasterApi, employeeMasterApi, userMasterApi,
  branchMasterApi, companyMasterApi, pageGroupMasterApi, termsAndConditionsMasterApi
} from "./services"

import {
  partyCategoryMasterApi, currencyMasterApi, partyMasterApi, ContentMasterApi, PortionMasterApi,
  YarnTypeMasterApi, CountsMasterApi, HsnMasterApi, YarnBlendMasterApi, YarnMasterApi,
  FabricTypeMasterApi, FabricMasterApi, AccessoryGroupMasterApi, AccessoryItemMasterApi,
  AccessoryMasterApi, ColorMasterApi, poApi, UnitOfMeasurementApi,
  PaytermMasterApi, TaxTermMasterApi, TaxTemplateApi, LocationMasterApi, GaugeMasterApi,
  DesignMasterApi, GsmMasterApi, LoopLengthMasterApi, DiaMasterApi, SizeMasterApi,
  PurchaseInwardOrReturnApi, stockApi, StyleMasterApi, ProcessMasterApi, SizeTemplateApi,
  ProcessDeliveryApi, ProcessInwardApi, CuttingOrderApi, CuttingDeliveryApi, CuttingReceiptApi, ProductionDeliveryApi, DirectInwardOrReturn, PayOutApi, LedgerApi, AdvanceAdjustmentApi, ProcessDeliveryReturnApi, ProductionReceiptApi, RawMaterialSalesApi, finishedGoodsSalesApi, RawMaterialOpeningStockApi, FinishedGoodsOpeningStockApi, finishedGoodsSalesDeliveryApi, PriceTemplateMasterServicesApi, PrintingJobWorkServicesApi, OnlineSalesDeliveryApi, OnlineSalesReturnApi, CreditDebitApi

} from "./ErpServices";
import designApi from "./ErpServices/DesignMasterServices";
import TermsAndConditionsMasterApi from "./services/TermsAndConditionsService";
import LossReasonApi from "./ErpServices/LossReasonMasterServices";
import billEntryApi from "./ErpServices/BillEntryServices";
import CuttingExcessFabricReturnApi from "./ErpServices/CuttingExcessFabricReturnServices";
import InterOfficeStockTransferFinishedGoodsApi from "./ErpServices/InterOfficeTransferFinishedGoodsServices";
import { DeliveryNoteApi, InvoiceApi, ProjectApi, ProjectImportApi, ProjectPhaseApi } from "./Construction";

const commonReducers = {
  openTabs,
  countryMaster: countryMasterApi.reducer,
  PortionMaster: PortionMasterApi.reducer,
  pageMaster: pageMasterApi.reducer,
  stateMaster: stateMasterApi.reducer,
  cityMaster: cityMasterApi.reducer,
  departmentMaster: departmentMasterApi.reducer,
  employeeCategoryMaster: employeeCategoryMasterApi.reducer,
  finYearMaster: finYearMasterApi.reducer,
  roleMaster: rolesMasterApi.reducer,
  userMaster: userMasterApi.reducer,
  employeeMaster: employeeMasterApi.reducer,
  branchMaster: branchMasterApi.reducer,
  companyMaster: companyMasterApi.reducer,
  pageGroupMaster: pageGroupMasterApi.reducer,
  termsAndConditionsMaster: termsAndConditionsMasterApi.reducer
}
const commonMiddleware = [countryMasterApi.middleware,
pageMasterApi.middleware,
stateMasterApi.middleware,
cityMasterApi.middleware,
departmentMasterApi.middleware,
employeeCategoryMasterApi.middleware,
finYearMasterApi.middleware,
rolesMasterApi.middleware,
userMasterApi.middleware,
employeeMasterApi.middleware,
branchMasterApi.middleware,
companyMasterApi.middleware,
pageGroupMasterApi.middleware,
TermsAndConditionsMasterApi.middleware
];


const erpReducers = {
  partyCategoryMaster: partyCategoryMasterApi.reducer,
  currencyMaster: currencyMasterApi.reducer,
  partyMaster: partyMasterApi.reducer,
  contentMaster: ContentMasterApi.reducer,
  yarnTypeMaster: YarnTypeMasterApi.reducer,
  countsMaster: CountsMasterApi.reducer,
  hsnMaster: HsnMasterApi.reducer,
  yarnBlendMaster: YarnBlendMasterApi.reducer,
  yarnMaster: YarnMasterApi.reducer,
  fabricTypeMaster: FabricTypeMasterApi.reducer,
  fabricMaster: FabricMasterApi.reducer,
  accessoryGroupMaster: AccessoryGroupMasterApi.reducer,
  accessoryItemMaster: AccessoryItemMasterApi.reducer,
  accessoryMaster: AccessoryMasterApi.reducer,
  colorMaster: ColorMasterApi.reducer,
  po: poApi.reducer,
  unitOfMeasurementMaster: UnitOfMeasurementApi.reducer,
  paytermMaster: PaytermMasterApi.reducer,
  taxTermMaster: TaxTermMasterApi.reducer,
  taxTemplate: TaxTemplateApi.reducer,
  locationMaster: LocationMasterApi.reducer,
  Gauge: GaugeMasterApi.reducer,
  gsm: GsmMasterApi.reducer,
  design: designApi.reducer,
  Dia: DiaMasterApi.reducer,
  LoopLength: LoopLengthMasterApi.reducer,
  sizeMaster: SizeMasterApi.reducer,
  PurchaseInwardOrReturn: PurchaseInwardOrReturnApi.reducer,
  stock: stockApi.reducer,
  styleMaster: StyleMasterApi.reducer,
  Process: ProcessMasterApi.reducer,
  SizeTemplate: SizeTemplateApi.reducer,
  ProcessDelivery: ProcessDeliveryApi.reducer,
  ProcessInward: ProcessInwardApi.reducer,
  [CuttingOrderApi.reducerPath]: CuttingOrderApi.reducer,
  [CuttingDeliveryApi.reducerPath]: CuttingDeliveryApi.reducer,
  [LossReasonApi.reducerPath]: LossReasonApi.reducer,
  [CuttingReceiptApi.reducerPath]: CuttingReceiptApi.reducer,
  [DirectInwardOrReturn.reducerPath]: DirectInwardOrReturn.reducer,
  [billEntryApi.reducerPath]: billEntryApi.reducer,
  [PayOutApi.reducerPath]: PayOutApi.reducer,
  [LedgerApi.reducerPath]: LedgerApi.reducer,
  [AdvanceAdjustmentApi.reducerPath]: AdvanceAdjustmentApi.reducer,
  [ProcessDeliveryReturnApi.reducerPath]: ProcessDeliveryReturnApi.reducer,
  [CuttingExcessFabricReturnApi.reducerPath]: CuttingExcessFabricReturnApi.reducer,
  [ProductionDeliveryApi.reducerPath]: ProductionDeliveryApi.reducer,
  [ProductionReceiptApi.reducerPath]: ProductionReceiptApi.reducer,
  [RawMaterialSalesApi.reducerPath]: RawMaterialSalesApi.reducer,
  [finishedGoodsSalesApi.reducerPath]: finishedGoodsSalesApi.reducer,
  [RawMaterialOpeningStockApi.reducerPath]: RawMaterialOpeningStockApi.reducer,
  [FinishedGoodsOpeningStockApi.reducerPath]: FinishedGoodsOpeningStockApi.reducer,
  [finishedGoodsSalesDeliveryApi.reducerPath]: finishedGoodsSalesDeliveryApi.reducer,
  [InterOfficeStockTransferFinishedGoodsApi.reducerPath]: InterOfficeStockTransferFinishedGoodsApi.reducer,
  [PriceTemplateMasterServicesApi.reducerPath]: PriceTemplateMasterServicesApi.reducer,
  [PrintingJobWorkServicesApi.reducerPath]: PrintingJobWorkServicesApi.reducer,
  [OnlineSalesDeliveryApi.reducerPath]: OnlineSalesDeliveryApi.reducer,
  [OnlineSalesReturnApi.reducerPath]: OnlineSalesReturnApi.reducer,
  [CreditDebitApi.reducerPath]: CreditDebitApi.reducer
}
const erpMiddleware = [
  partyCategoryMasterApi.middleware,
  currencyMasterApi.middleware,
  partyMasterApi.middleware,
  ContentMasterApi.middleware,
  PortionMasterApi.middleware,
  YarnTypeMasterApi.middleware,
  CountsMasterApi.middleware,
  HsnMasterApi.middleware,
  YarnBlendMasterApi.middleware,
  YarnMasterApi.middleware,
  FabricTypeMasterApi.middleware,
  FabricMasterApi.middleware,
  AccessoryGroupMasterApi.middleware,
  AccessoryItemMasterApi.middleware,
  AccessoryMasterApi.middleware,
  ColorMasterApi.middleware,
  poApi.middleware,
  UnitOfMeasurementApi.middleware,
  PaytermMasterApi.middleware,
  TaxTermMasterApi.middleware,
  TaxTemplateApi.middleware,
  LocationMasterApi.middleware,
  DiaMasterApi.middleware,
  GsmMasterApi.middleware,
  GaugeMasterApi.middleware,
  LoopLengthMasterApi.middleware,
  DesignMasterApi.middleware,
  SizeMasterApi.middleware,
  PurchaseInwardOrReturnApi.middleware,
  stockApi.middleware,
  StyleMasterApi.middleware,
  ProcessMasterApi.middleware,
  SizeTemplateApi.middleware,
  ProcessDeliveryApi.middleware,
  ProcessInwardApi.middleware,
  CuttingOrderApi.middleware,
  CuttingDeliveryApi.middleware,
  LossReasonApi.middleware,
  CuttingReceiptApi.middleware,
  DirectInwardOrReturn.middleware,
  billEntryApi.middleware,
  PayOutApi.middleware,
  LedgerApi.middleware,
  AdvanceAdjustmentApi.middleware,
  ProcessDeliveryReturnApi.middleware,
  CuttingExcessFabricReturnApi.middleware,
  ProductionDeliveryApi.middleware,
  ProductionReceiptApi.middleware,
  RawMaterialSalesApi.middleware,
  finishedGoodsSalesApi.middleware,
  RawMaterialOpeningStockApi.middleware,
  FinishedGoodsOpeningStockApi.middleware,
  finishedGoodsSalesDeliveryApi.middleware,
  InterOfficeStockTransferFinishedGoodsApi.middleware,
  PriceTemplateMasterServicesApi.middleware,
  PrintingJobWorkServicesApi.middleware,
  OnlineSalesDeliveryApi.middleware,
  OnlineSalesReturnApi.middleware,
  CreditDebitApi.middleware
]

const constructionReducers = {
  [ProjectApi.reducerPath]: ProjectApi.reducer,
  [ProjectImportApi.reducerPath]: ProjectImportApi.reducer,
  [ProjectPhaseApi.reducerPath]: ProjectPhaseApi.reducer,
  [DeliveryNoteApi.reducerPath]: DeliveryNoteApi.reducer,
  [InvoiceApi.reducerPath]: InvoiceApi.reducer,
}

const contructionMiddleware = [
  ProjectApi.middleware,
  ProjectImportApi.middleware,
  ProjectPhaseApi.middleware,
  DeliveryNoteApi.middleware,
  InvoiceApi.middleware,
]

const store = configureStore({
  reducer: {
    ...commonReducers, ...erpReducers, ...constructionReducers
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(commonMiddleware).concat(erpMiddleware).concat(contructionMiddleware),
});
export default store;