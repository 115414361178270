import React, { useEffect, useState, useRef, useCallback } from "react";
import {
    useGetDeliveryNoteQuery,
    useGetDeliveryNoteByIdQuery,
    useAddDeliveryNoteMutation,
    useUpdateDeliveryNoteMutation,
    useDeleteDeliveryNoteMutation,
} from "../../../redux/Construction/DeliveryNoteServices";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices";
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { DisabledInput, DateInput, DropdownInput, TextInput, validateVehicelNumber } from "../../../Inputs";
import { dropDownListObject, } from '../../../Utils/contructObject';
import { PDFViewer } from '@react-pdf/renderer';

import Modal from "../../../UiComponents/Modal";
import FormReport from "./FormReport";

import { convertGroupedObjectIntoArray, getCommonParams } from "../../../Utils/helper";
import ProjectNameSelectAndCreateComponent from "../../../ERP/ReusableComponents/ProjectNameSelectAndCreateComponent";
import ProjectPhaseSelectAndCreateComponent from "../../../ERP/ReusableComponents/ProjectPhaseSelectAndCreateComponent";
import moment from "moment";
import DeliveryDetails from "./DeliveryDetails";
import SelectionFillGrid from "./SelectionGrid";
import PrintFormat from "./PrintFormat";
import tw from "../../../Utils/tailwind-react-pdf";
import { groupBy } from "lodash";
import { useGetProjectPhaseByIdQuery } from "../../../redux/Construction/ProjectPhaseServices";
const MODEL = "Delivery Note";

export default function Form() {

    const [readOnly, setReadOnly] = useState(false);
    const [docId, setDocId] = useState("")
    const [id, setId] = useState("");
    const [formReport, setFormReport] = useState(false);
    const [projectId, setProjectId] = useState("");
    const [projectPhaseId, setProjectPhaseId] = useState("");
    const [partyId, setPartyId] = useState("");
    const [date, setDate] = useState("");
    const [deliveryDetails, setDeliveryDetails] = useState([]);
    const [isSelection, setIsSelection] = useState(false);
    const [containerNo, setContainerNo] = useState("");
    const [truckNo, setTruckNo] = useState("");
    const [driverName, setDriverName] = useState("");
    const [print, setPrint] = useState(false);
    const [groupedDeliveryDetails, setGroupedDeliveryDetails] = useState([]);

    const childRecord = useRef(0);


    const { branchId, companyId, finYearId, userId } = getCommonParams()

    const params = {
        branchId, companyId, finYearId
    };

    const { data: supplierList } =
        useGetPartyQuery({ params: { ...params } });

    const { data: allData, isLoading, isFetching } = useGetDeliveryNoteQuery({ params, searchParams: '' });

    const { data: projectPhase } = useGetProjectPhaseByIdQuery(projectPhaseId, { skip: !projectPhaseId })

    const isDrawingWeight = projectPhase?.data?.Project?.isDrawingWeight;


    const getNextDocId = useCallback(() => {
        if (id || isLoading || isFetching) return
        if (allData?.nextDocId) {
            setDocId(allData.nextDocId)
        }
    }, [allData, isLoading, isFetching, id])

    useEffect(getNextDocId, [getNextDocId])

    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetDeliveryNoteByIdQuery(id, { skip: !id });

    const [addData] = useAddDeliveryNoteMutation();
    const [updateData] = useUpdateDeliveryNoteMutation();
    const [removeData] = useDeleteDeliveryNoteMutation();

    const syncFormWithDb = useCallback((data) => {
        if (id) {
            setReadOnly(true);
        } else {
            setReadOnly(false);
        }
        if (data?.docId) {
            setDocId(data?.docId)
        }
        setDate(data?.createdAt ? moment(data?.createdAt).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"));
        setPartyId(data?.ProjectPhase?.Project?.partyId ? data?.ProjectPhase?.Project?.partyId : "");
        setProjectId(data?.ProjectPhase?.projectId ? data?.ProjectPhase?.projectId : "");
        setProjectPhaseId(data?.projectPhaseId ? data?.projectPhaseId : "");
        setDeliveryDetails(data?.DeliveryNoteDetails || []);
        setContainerNo(data?.containerNo || "");
        setTruckNo(data?.truckNo || "");
        setDriverName(data?.driverName || "");
        setGroupedDeliveryDetails(data?.DeliveryNoteGroupedDetails || []);
    }, [id]);



    useEffect(() => {
        if (id) {
            syncFormWithDb(singleData?.data);
        } else {
            syncFormWithDb(undefined);
        }
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    useEffect(() => {
        if (id) return
        setTruckNo(containerNo)
    }, [id, containerNo])

    const data = {
        branchId, id, userId,
        finYearId,
        projectPhaseId, deliveryDetails,
        containerNo, truckNo: truckNo.replaceAll(" ", ''), driverName,
        groupedDeliveryDetails
    }


    const validateData = (data) => {
        return data.projectPhaseId && (data.deliveryDetails.length > 0) && data.truckNo
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            let returnData;
            if (text === "Updated") {
                returnData = await callback(data).unwrap();
            } else {
                returnData = await callback(data).unwrap();
            }
            if (returnData.statusCode === 1) {
                return toast.error(`${returnData.message}`)
            } else {
                onNew()
                toast.success(text + "Successfully");
            }
        } catch (error) {
            console.log("handle");
        }
    };


    const saveData = () => {
        if (!validateVehicelNumber(data.truckNo)) {
            toast.info("Invalid Vehicle No.", { position: "top-center" })
            return
        }
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", { position: "top-center" })
            return
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    }

    function generateEwayBill() {
        if (id) {
            handleSubmitCustom(updateData, { id, isGenerateEwayBill: true }, "E-way Generated")
        }
    }

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                onNew();
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = async () => {
        setId("");
        setReadOnly(false);
        syncFormWithDb(undefined)
        getNextDocId();
    };


    useEffect(() => {
        let groupedObject = groupBy(deliveryDetails, "item_group");
        groupedObject = convertGroupedObjectIntoArray(groupedObject);
        setGroupedDeliveryDetails(prev => {
            return groupedObject.map(i => ({
                ...i,
                physical_weight: prev.find(pI => pI.groupName === i.groupName)?.physical_weight || 0,
                weight: (i?.items.reduce((a, c) => a + parseFloat(c.weight), 0)).toFixed("3"),
                hsn: i.items[0]['hsn'],
                tax: i.items[0]['tax'],
                rate: i.items[0]['rate'],
                uom: i.items[0]['uom'],
            }))
        });
    }, [deliveryDetails]);

    const tableHeadings = ["PoNo", "PoDate", "transType", "DueDate", "Supplier"]
    const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']

    let supplierListBasedOnSupply = supplierList ? supplierList.data : [];

    return (
        <>
            <Modal isOpen={isSelection} onClose={() => { setIsSelection(false) }} widthClass={'w-[90%] h-[90%] overflow-auto'} >
                <SelectionFillGrid projectPhaseId={projectPhaseId} deliveryDetails={deliveryDetails} setDeliveryDetails={setDeliveryDetails} onClose={() => { setIsSelection(false) }} />
            </Modal>
            <Modal isOpen={print} onClose={() => { setPrint(false) }} widthClass={"w-[90%] h-[90%]"} >
                <PDFViewer style={tw("w-full h-full")}>
                    <PrintFormat isDrawingWeight={isDrawingWeight} key={deliveryDetails} data={singleData?.data} />
                </PDFViewer>
            </Modal>
            <div
                onKeyDown={handleKeyDown}
                className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto">
                <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
                    <FormReport
                        heading={MODEL}
                        tableHeaders={tableHeadings}
                        tableDataNames={tableDataNames}
                        loading={
                            isLoading || isFetching
                        }
                        tableWidth="100%"
                        data={allData?.data}
                        onClick={(id) => {
                            setId(id);
                            setFormReport(false);
                        }
                        }
                    />
                </Modal>
                <div className="flex flex-col frame w-full h-full">
                    <FormHeader
                        onNew={onNew}
                        model={MODEL}
                        saveData={saveData}
                        setReadOnly={(value) => {
                            if (singleData?.data?.childRecord > 0) {
                                return toast.info("Invoice Already Generated...!");
                            }
                            setReadOnly(value)
                        }}
                        deleteData={deleteData}
                        openReport={() => { setFormReport(true) }}
                        childRecord={childRecord.current}
                        onPrint={id ? () => setPrint(true) : null}
                        onEscape={() => {
                            setFormReport(false);
                            setIsSelection(false);
                            setPrint(false);
                        }}
                    />
                    <div className="flex-1 grid gap-x-2">
                        <div className="col-span-3 grid ">
                            <div className='col-span-3 grid '>
                                <div className='mr-1'>
                                    <div className={`grid`}>
                                        <div className={"flex flex-col"}>
                                            <fieldset className='frame rounded-tr-lg rounded-bl-lg w-full border border-gray-600 px-3 overflow-auto h-[100px]'>
                                                <legend className='sub-heading'>Delivery Info</legend>
                                                <div className='flex flex-col justify-center items-start flex-1 w-full'>
                                                    <div className="grid grid-cols-5 w-full">
                                                        <DisabledInput name="Doc Id." value={docId} required={true}
                                                        />
                                                        <DateInput name="Doc Date" value={date} type={"date"} required={true} readOnly={readOnly} disabled />
                                                        <DropdownInput name="Customer" options={dropDownListObject(supplierListBasedOnSupply, "aliasName", "id")} value={partyId} setValue={setPartyId} required={true} readOnly={id} />
                                                        <ProjectNameSelectAndCreateComponent dropDownInput readOnly={readOnly || (!partyId)} partyId={partyId} projectId={projectId} setProjectId={setProjectId} />
                                                        <ProjectPhaseSelectAndCreateComponent
                                                            dropDownInput
                                                            readOnly={readOnly || !(projectId)}
                                                            projectId={projectId}
                                                            projectPhaseId={projectPhaseId}
                                                            setProjectPhaseId={setProjectPhaseId}
                                                        />
                                                        <TextInput name={"Container No."} value={containerNo} setValue={setContainerNo} readOnly={readOnly} />
                                                        <TextInput name={"Truck No."} required value={truckNo} setValue={setTruckNo} readOnly={readOnly} />
                                                        <TextInput name={"Driver Name."} value={driverName} setValue={setDriverName} readOnly={readOnly} />
                                                        {/* 
                                                        {id && (!singleData?.data?.ewayBillNo) &&
                                                            <button onClick={generateEwayBill} className="bg-green-600 text-white p-1 rounded">
                                                                Generate Eway Bill
                                                            </button>
                                                        } */}
                                                        {singleData?.data?.ewayBillNo &&
                                                            <>
                                                                <DisabledInput name="E-way Bill No" value={singleData?.data?.ewayBillNo}
                                                                />
                                                                <DisabledInput name="E-way Bill Date"
                                                                    value={singleData?.data?.ewayBillResponse?.ewayBillDate}
                                                                />
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg my-1 w-full border border-gray-600 md:pb-5 flex flex-1 overflow-auto'>
                                                <legend className='sub-heading'>Delivery Details</legend>
                                                <DeliveryDetails readOnly={readOnly} isDrawingWeight={isDrawingWeight} groupedDeliveryDetails={groupedDeliveryDetails} setGroupedDeliveryDetails={setGroupedDeliveryDetails} setDeliveryDetails={setDeliveryDetails} onOpen={() => setIsSelection(true)} />
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}