import React from 'react'
import { Text, View } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import { getInvoiceTotal } from '../../../Utils/helper';
const PrintGrid = ({ items, isIGst }) => {
    return (
        <>
            <View style={tw("w-full border-x border-y border-gray-500 text-center flex flex-row h-[30px] text-[7px] font-[Times-Bold]")} wrap={false}>
                <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[5%]")}>
                    <Text>
                        Sr.No
                    </Text>
                </View>
                <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[20%]")}>
                    <Text>
                        Description Of Goods
                    </Text>
                </View>
                <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[10%]")}>
                    <Text>
                        HSN / SAC CODE
                    </Text>
                </View>
                <View style={tw("flex flex-col border-r border-gray-500 justify-center items-center w-[25%] h-full")}>
                    <View style={tw("h-3/6 flex flex-col items-center justify-center")}>
                        <Text style={tw("")}>
                            Identification Marks
                        </Text>
                        <Text style={tw("")}>
                            of the goods
                        </Text>
                    </View>
                    <View style={tw("w-full flex flex-row h-3/6 border-t border-gray-500 items-center justify-center")}>
                        <View style={tw("w-2/5 flex flex-col items-center justify-center h-full border-r border-gray-500 ")}>
                            <Text>
                                QTY
                            </Text>
                        </View>
                        <View style={tw("w-1/5 flex flex-col items-center justify-center h-full border-r border-gray-500 ")}>
                            <Text>
                                UOM
                            </Text>
                        </View>
                        <View style={tw("w-2/5 flex flex-col items-center justify-center h-full ")}>
                            <Text>
                                RATE
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[12%]")}>
                    <Text>
                        TAXABLE VALUE
                    </Text>
                </View>
                <View style={tw("flex flex-row border-r border-gray-500 items-center w-[15%] h-full flex flex-row items-center justify-center")}>
                    {isIGst ?
                        <View>
                            <Text>
                                IGST
                            </Text>
                            <Text>
                                {items[0] && items[0]?.['tax']} %
                            </Text>
                        </View>
                        :
                        <>
                            <View style={tw("w-1/2 border-r border-gray-500 h-full flex flex-row items-center justify-center")}>
                                <View>
                                    <Text>
                                        SGST
                                    </Text>
                                    <Text>
                                        {(parseFloat(items[0]?.tax || 0) / 2)} %
                                    </Text>
                                </View>
                            </View>
                            <View style={tw("w-1/2 h-full flex flex-row items-center justify-center")}>
                                <View>
                                    <Text >
                                        CGST
                                    </Text>
                                    <Text>
                                        {(parseFloat(items[0]?.tax || 0) / 2)} %
                                    </Text>
                                </View>
                            </View>
                        </>
                    }
                </View>
                <View style={tw("flex flex-row justify-center items-center w-[15%] text-[6px]")}>
                    <Text>
                        TOTAL VALUE OF INVOICE In. Rupees (INR)
                    </Text>
                </View>
            </View>
            {items.map((item, index) =>
                <View key={index} style={tw("w-full h-[20px] text-[7px] border-x border-b border-gray-500 text-center flex flex-row")} wrap={false}>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[5%]")}>
                        <Text>
                            {index + 1}
                        </Text>
                    </View>
                    <View style={tw("flex flex-col border-r border-gray-500 justify-around items-center w-[20%]")}>
                        <Text key={index} style={tw('text-center')}>
                            {item?.groupName}
                        </Text>
                    </View>
                    <View style={tw("flex flex-col border-r border-gray-500 justify-around items-center w-[10%]")}>
                        <Text key={index} style={tw('text-center')}>
                            {item?.hsn}
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[25%]")}>
                        <View style={tw("w-2/5 flex flex-col items-center justify-center h-full border-r border-gray-500 ")}>
                            <Text>
                                {isNaN(parseFloat(item?.qty || 0)) ? item.qty : parseFloat(item?.qty || 0).toFixed(2)}
                            </Text>
                        </View>
                        <View style={tw("w-1/5 flex flex-col items-center justify-center h-full border-r border-gray-500 ")}>
                            <Text>
                                {item?.uom}
                            </Text>
                        </View>
                        <View style={tw("w-2/5 flex flex-col items-center justify-center h-full ")}>
                            <Text>
                                {parseFloat(item?.rate || 0).toFixed(2)}
                            </Text>
                        </View>
                    </View>
                    <View style={tw("flex flex-col border-gray-500 border-r justify-around text-center items-center w-[12%] h-full")}>
                        <Text>
                            {getInvoiceTotal(item)?.taxableValue}
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[15%] h-full")}>
                        {isIGst ?
                            <Text>
                                {getInvoiceTotal(item)?.taxValue}
                            </Text>
                            :
                            <>
                                <View style={tw("w-1/2 border-r border-gray-500 h-full flex flex-row items-center justify-center")}>
                                    <View>
                                        <Text>
                                            {getInvoiceTotal(item)?.splitValue}
                                        </Text>
                                    </View>
                                </View>
                                <View style={tw("w-1/2 h-full flex flex-row items-center justify-center")}>
                                    <View>
                                        <Text>
                                            {getInvoiceTotal(item)?.splitValue}
                                        </Text>
                                    </View>
                                </View>
                            </>
                        }
                    </View>
                    <View style={tw("flex flex-row justify-center items-center w-[15%] h-full")}>
                        <Text>
                            {
                                getInvoiceTotal(item)?.totalAmount
                            }
                        </Text>
                    </View>
                </View>
            )}
            {Array.from({ length: 8 - items.length }).map((_, i) =>
                <View key={i} style={tw("w-full h-[20px] text-[7px] border-x border-b border-gray-500 text-center flex flex-row")} wrap={false}>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[5%]")}>
                        <Text>
                        </Text>
                    </View>
                    <View style={tw("flex flex-col border-r border-gray-500 justify-around items-center w-[20%]")}>
                        <Text style={tw('text-center')}>
                        </Text>
                    </View>
                    <View style={tw("flex flex-col border-r border-gray-500 justify-around items-center w-[10%]")}>
                        <Text style={tw('text-center')}>
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[25%]")}>
                        <View style={tw("w-2/5 flex flex-col items-center justify-center h-full border-r border-gray-500 ")}>
                            <Text>
                            </Text>
                        </View>
                        <View style={tw("w-1/5 flex flex-col items-center justify-center h-full border-r border-gray-500 ")}>
                            <Text>
                            </Text>
                        </View>
                        <View style={tw("w-2/5 flex flex-col items-center justify-center h-full ")}>
                            <Text>
                            </Text>
                        </View>
                    </View>
                    <View style={tw("flex flex-col border-gray-500 justify-around text-center items-center w-[12%]")}>
                        <Text>
                        </Text>
                    </View>
                    <View style={tw("flex flex-row border-r border-gray-500 justify-center items-center w-[15%]")}>
                        {isIGst ?
                            <Text>
                            </Text>
                            :
                            <>
                                <View style={tw("w-1/2 border-r border-gray-500 h-full flex flex-row items-center justify-center")}>
                                    <View>
                                        <Text>
                                        </Text>
                                    </View>
                                </View>
                                <View style={tw("w-1/2 h-full flex flex-row items-center justify-center")}>
                                    <View>
                                        <Text>
                                        </Text>
                                    </View>
                                </View>
                            </>
                        }
                    </View>
                    <View style={tw("flex flex-row justify-center items-center w-[15%]")}>
                        <Text>
                        </Text>
                    </View>
                </View>
            )}
        </>
    )
}

export default PrintGrid