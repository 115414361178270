import React from 'react'
import { Text, View } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf';
import { getInvoiceTotal } from '../../../Utils/helper';

const PrintTotalGrid = ({ items, isIGst }) => {
    const totalQty = items.reduce((a, c) => a + parseFloat(c.qty), 0).toFixed(2);
    const totalTaxValue = items.reduce((a, c) => a + parseFloat(getInvoiceTotal(c).taxValue), 0).toFixed(2);
    const totalTaxValueSplit = items.reduce((a, c) => a + parseFloat(getInvoiceTotal(c).splitValue), 0).toFixed(2);
    const totalAmount = items.reduce((a, c) => a + parseFloat(getInvoiceTotal(c).totalAmount), 0).toFixed(2);
    const totalTaxableValue = items.reduce((a, c) => a + parseFloat(getInvoiceTotal(c).taxableValue), 0).toFixed(2);
    const taxPercent = items[0]?.tax || 0;
    return (
        <View style={tw("w-full border-x border-b border-gray-500 text-center flex flex-row h-[170px]")}>
            <View style={tw("w-7/12 border-r border-gray-500 flex flex-col h-full p-1 justify-center ")}>
                <View style={tw("h-[75%] w-full")}>
                    <Text style={tw("text-xs font-bold w-full text-sky-900 text-left mb-1")}>
                        Terms & Conditions:
                    </Text>
                    <Text style={tw("text-xs text-gray-700 text-left mb-1")}>
                        1.  if payment is not made on or before due date, Interest@18% pa and applicable GST and other applicable taxes if any will be charges for actual delayed days
                    </Text>
                    <Text style={tw("text-xs text-gray-700 text-left mb-1")}>
                        2. complaint if any on quality shall be reported within 15days from the date of receipt of goods and before taking them into further processing at your end
                    </Text>
                    <Text style={tw("text-xs text-gray-700 text-left mb-1")}>
                        3.Any disbute under this invoice will settled by competent court within Hyderabad jurisdiction only.
                    </Text>
                    <Text style={tw("text-xs text-gray-700 text-left mb-1")}>
                        4. Complaint if any on quality or physical condition of goods shall be reported within 24 of receipt of material when freight is in our scope.
                    </Text>
                </View>
                <View style={tw("h-[25%] w-full flex flex-row text-xs border-t border-gray-500")}>
                    <View style={tw("w-1/2 h-full text-left")}>
                        <Text style={tw("")}>
                            LC Number:
                        </Text>
                        <Text>
                            LC Details:
                        </Text>
                    </View>
                    <View style={tw("w-1/2 h-full text-left")}>
                        <Text>
                            LC Date:
                        </Text>
                    </View>
                </View>
            </View>
            <View style={tw("w-5/12 flex flex-col h-full text-[8px] p-1")}>
                <View style={tw("h-full w-full")}>
                    <View style={tw("flex flex-row border-b border-gray-500 h-[10%] w-full")}>
                        <Text style={tw("text-xs font-bold text-sky-900 w-3/4 text-left")}>
                            SUB-TOTAL
                        </Text>
                        <Text style={tw("text-xs font-bold text-sky-900 w-1/4 text-right ")}>
                            {totalTaxableValue}
                        </Text>
                    </View>
                    <View style={tw("h-[45%] flex flex-col items-center w-full border-b border-gray-500")}>
                        <View style={tw("flex flex-row w-full h-1/4")}>
                            <Text style={tw("text-xs w-3/4 text-left ")}>
                                Freight/Delivery Charges
                            </Text>
                            <Text style={tw("text-xs w-1/4 text-right ")}>
                                0.00
                            </Text>
                        </View>
                        <View style={tw("flex flex-row w-full h-1/4")}>
                            <Text style={tw("text-xs w-3/4 text-left ")}>
                                CF Discount
                            </Text>
                            <Text style={tw("text-xs w-1/4 text-right ")}>
                                0.00
                            </Text>
                        </View>
                        <View style={tw("flex flex-row w-full h-1/4")}>
                            <Text style={tw("text-xs w-3/4 text-left ")}>
                                Packing and Forwarding Charges
                            </Text>
                            <Text style={tw("text-xs w-1/4 text-right ")}>
                                0.00
                            </Text>
                        </View>
                        <View style={tw("flex flex-row w-full h-1/4")}>
                            <Text style={tw("text-xs w-3/4 text-left")}>
                                Insurance
                            </Text>
                            <Text style={tw("text-xs w-1/4 text-right ")}>
                                0.00
                            </Text>
                        </View>
                    </View>
                    <View style={tw("h-[45%] flex flex-col items-center w-full")}>
                        <View style={tw("flex flex-row w-full h-1/3")}>
                            <Text style={tw("text-xs w-[60%] text-left ")}>
                                Integrated Goods & Service Tax (IGST)
                            </Text>
                            <Text style={tw("w-[20%]")}>
                                {isIGst ?
                                    <>
                                        {taxPercent} %
                                    </>
                                    :
                                    ""
                                }
                            </Text>
                            <Text style={tw("text-xs w-[20%] text-right")}>
                                {isIGst ?
                                    totalTaxValue
                                    :
                                    '0.00'
                                }
                            </Text>
                        </View>
                        <View style={tw("flex flex-row w-full h-1/3")}>
                            <Text style={tw("text-xs w-[60%] text-left ")}>
                                Central Goods & Service Tax (CGST)
                            </Text>
                            <Text style={tw("w-[20%]")}>
                                {!isIGst && `${taxPercent}%`}
                            </Text>
                            <Text style={tw("text-xs w-[20%] text-right")}>
                                {isIGst ?
                                    '0.00'
                                    :
                                    totalTaxValueSplit
                                }
                            </Text>
                        </View>
                        <View style={tw("flex flex-row w-full h-1/3")}>
                            <Text style={tw("text-xs w-[60%] text-left ")}>
                                State Goods & Service Tax (SGST)
                            </Text>
                            <Text style={tw("w-[20%]")}>
                                {!isIGst && `${taxPercent}%`}
                            </Text>
                            <Text style={tw("text-xs w-[20%] text-right")}>
                                {isIGst ?
                                    '0.00'
                                    :
                                    totalTaxValueSplit
                                }
                            </Text>
                        </View>
                        <View style={tw("flex flex-row w-full h-1/3")}>
                            <Text style={tw("text-xs w-[60%] text-left ")}>
                                TCS
                            </Text>
                            <Text style={tw("w-[20%]")}>
                            </Text>
                            <Text style={tw("text-xs w-[20%] text-right")}>
                            </Text>
                        </View>
                        <View style={tw("flex flex-row w-full h-1/3")}>
                            <Text style={tw("text-xs w-[60%] text-left ")}>
                                Round Off
                            </Text>
                            <Text style={tw("w-[20%]")}>

                            </Text>
                            <Text style={tw("text-xs w-[20%] text-right")}>

                            </Text>
                        </View>
                        <View style={tw("flex flex-row w-full h-1/3")}>
                            <Text style={tw("text-xs w-[60%] text-left ")}>
                                Total Invoice Value (INR)
                            </Text>
                            <Text style={tw("w-[20%]")}>
                            </Text>
                            <Text style={tw("text-xs w-[20%] text-right")}>
                                {totalAmount}
                            </Text>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default PrintTotalGrid
