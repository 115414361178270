import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery"; import { FINISHED_GOODS_SALES_DELIVERY_API } from "../../Api";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

const FinishedGoodsSalesDeliveryApi = createApi({
  reducerPath: "FinishedGoodsSalesDelivery",
  baseQuery,
  tagTypes: ["FinishedGoodsSalesDelivery"],
  endpoints: (builder) => ({
    getFinishedGoodsSalesDelivery: builder.query({
      query: ({ params, searchParams }) => {
        if (searchParams) {
          return {
            url: FINISHED_GOODS_SALES_DELIVERY_API + "/search/" + searchParams,
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
            params
          };
        }
        return {
          url: FINISHED_GOODS_SALES_DELIVERY_API,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          params
        };
      },
      providesTags: ["FinishedGoodsSalesDelivery"],
    }),
    getFinishedGoodsSalesDeliveryById: builder.query({
      query: ({ id }) => {
        return {
          url: `${FINISHED_GOODS_SALES_DELIVERY_API}/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
      },
      providesTags: ["FinishedGoodsSalesDelivery"],
    }),
    addFinishedGoodsSalesDelivery: builder.mutation({
      query: (payload) => ({
        url: FINISHED_GOODS_SALES_DELIVERY_API,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["FinishedGoodsSalesDelivery"],
    }),
    updateFinishedGoodsSalesDelivery: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${FINISHED_GOODS_SALES_DELIVERY_API}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["FinishedGoodsSalesDelivery"],
    }),
    deleteFinishedGoodsSalesDelivery: builder.mutation({
      query: (id) => ({
        url: `${FINISHED_GOODS_SALES_DELIVERY_API}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FinishedGoodsSalesDelivery"],
    }),
  }),
});

export const {
  useGetFinishedGoodsSalesDeliveryQuery,
  useGetFinishedGoodsSalesDeliveryByIdQuery,
  useAddFinishedGoodsSalesDeliveryMutation,
  useUpdateFinishedGoodsSalesDeliveryMutation,
  useDeleteFinishedGoodsSalesDeliveryMutation,
} = FinishedGoodsSalesDeliveryApi;

export default FinishedGoodsSalesDeliveryApi;
