export const INITIAL_PAGE_NUMBER = 1;
export const DATA_PER_PAGE = 30;
export const COMPANY_NAME = 'Hansem Building Systems India Private Limited';
export const TN_ADDRESS = `CORP OFF: 114, SHREE ARTHANARI TOWERS, SIXTH FLOOR, RACE COURSE RD, COIMBATORE,
TAMIL NADU 641018
`;
export const AN_ADDRESS = `NO. 6-948/3, DVR, RW NO.06JAGGAIAHPET, NTR, ANDHRA PRADESH , 521175`;
export const WEBSITE = 'WWW.HANSEM.IN'
export const MOBILE_NO = '96130 56666';
export const GST_NO = '37AAECH7604N1Z7';
export const ORIENTATION_LANSCAPE = 'landscape';
export const CIN = 'U28999TZ2018PTC031440';
export const TAN = 'CMBH04026B';
