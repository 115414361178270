import React from 'react'
import { Text, View } from '@react-pdf/renderer';
import tw from '../../../Utils/tailwind-react-pdf'

const PrintHeader = () => {
    return (
        <View style={tw("text-center text-xl flex flex-row w-full h-[30px] border border-gray-500 items-center")}>
            <View style={tw("w-1/4 flex flex-row items-center justify-center")}>
            </View>
            <View style={tw("w-2/4")}>
                <View style={tw(" text-sm p-1")}>
                    <Text style={tw("text-base font-[Times-Bold] text-sky-900")}>
                        TAX INVOICE
                    </Text>
                    <Text style={tw("text-sm ")}>
                        (Section 31 read with Rule 46 of the GST Invoice Rules,2017)
                    </Text>
                </View>
            </View>
        </View>
    )
}

export default PrintHeader