import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useGetProjectPhaseByIdQuery } from '../../../redux/Construction/ProjectPhaseServices';
import { stringToLiteral } from '../../../Utils/helper';


const SelectionGrid = ({ deliveryDetails, setDeliveryDetails, projectPhaseId, onClose }) => {
    const [tempFillGridData, setTempFillGridData] = useState(deliveryDetails);

    const [localItems, setLocalItems] = useState([]);
    const [searchFilter, setSearchFilter] = useState([]);

    const { data } = useGetProjectPhaseByIdQuery(projectPhaseId, { skip: !projectPhaseId })

    const stockItems = useMemo(() => data?.data?.projectPhaseDetails || [], [data]);


    const filterRecords = useCallback((localItems) => {
        let newLocalItems = [...localItems]
        newLocalItems = newLocalItems.filter(item => {
            for (let filter of searchFilter) {
                let fieldName = filter.fieldName
                let searchValue = filter.searchValue
                let objTransformFunc = filter.objTransformFunc
                let itemObj = objTransformFunc(item[fieldName])
                if (!(`${itemObj}`.toLowerCase().includes(searchValue.toLowerCase()))) return false
            }
            return true
        })
        return newLocalItems
    }, [searchFilter])

    useEffect(() => {
        setLocalItems(filterRecords(stockItems))
    }, [setLocalItems, stockItems, filterRecords])

    const addItem = (item) => {
        setTempFillGridData(prev => [...prev, item])
    }

    const removeItem = (item) => {
        setTempFillGridData(prev => prev.filter(i => parseInt(i.projectPhaseDetailsId) !== parseInt(item.projectPhaseDetailsId)))
    }

    const isItemChecked = (item) => {
        return tempFillGridData.findIndex(i => parseInt(i.projectPhaseDetailsId) === parseInt(item.projectPhaseDetailsId)) !== -1
    }

    function handleCheckBoxChange(value, item) {
        if (value) {
            addItem(item)
        } else {
            removeItem(item)
        }
    }

    function handleSelectAllChange(value) {
        if (value) {
            localItems.forEach(item => addItem(item))
        } else {
            localItems.forEach(item => removeItem(item))
        }
    }

    function getSelectAll() {
        return localItems.every(item => isItemChecked(item))
    }


    function changeSearchFilter(field, value, objTransformFunc) {
        setSearchFilter(prev => {
            if (!value) {
                return prev.filter(item => item.fieldName !== field)
            }
            let newSearchFilter = [...prev]
            let index = newSearchFilter.findIndex(item => item.fieldName === field)
            if (index !== -1) {
                newSearchFilter[index]["searchValue"] = value
            } else {
                newSearchFilter.push({ fieldName: field, searchValue: value, objTransformFunc });
            }
            return newSearchFilter
        })
    }
    function getTotalStock() {
        return parseFloat(0).toFixed(3)
    }

    function onDone() {
        setDeliveryDetails(tempFillGridData);
        onClose();
    }
    function onBack() {
        onClose();
    }

    let count = 1;
    return (
        <div className='table-data bg-gray-200 h-full p-10'>
            <div className='overflow-auto h-full'>
                <table className='table-fixed w-full text-xs'>
                    <thead className='sticky top-0'>
                        <tr className='bg-blue-200'>
                            <th className='w-5'>
                                Mark All
                                <input type="checkbox" className='w-full' onChange={(e) => handleSelectAllChange(e.target.checked)}
                                    checked={getSelectAll()}
                                />
                            </th>
                            <th className='table-data  w-5'>
                                S.no
                            </th>
                            <th className='table-data w-16'>
                                <span>
                                    Item group
                                </span>
                                <input className='w-full p-2 table-data-input border ' type="text" placeholder='Search'
                                    onChange={(e) => {
                                        changeSearchFilter("item_group", e.target.value, (item) => item)
                                    }} />
                            </th>
                            <th className='table-data w-16'>
                                <span>
                                    Assy Mark
                                </span>
                                <input className='w-full p-2 table-data-input border ' type="text" placeholder='Search'
                                    onChange={(e) => {
                                        changeSearchFilter("assy_mark", e.target.value, (item) => item)
                                    }} />
                            </th>
                            <th className='table-data w-16'>
                                <span>
                                    Name
                                </span>
                                <input className='w-full p-2 table-data-input border ' type="text" placeholder='Search'
                                    onChange={(e) => {
                                        changeSearchFilter("name", e.target.value, (item) => item)
                                    }} />
                            </th>
                            <th className='table-data  w-16'>
                                <span>
                                    Profile
                                </span>
                                <input className='w-full p-2 table-data-input border' type="text" placeholder='Search'
                                    onChange={(e) => {
                                        changeSearchFilter("profile", e.target.value, (item) => item)
                                    }}
                                />
                            </th>
                            <th className='table-data  w-16'>
                                Qty
                            </th>
                            <th className='table-data  w-16'>
                                Area
                            </th>
                            <th className='table-data  w-16'>
                                Unit Weight
                            </th>
                            <th className='table-data  w-16'>
                                Weight
                            </th>
                            <th className='table-data  w-16'>
                                Length
                            </th>
                            <th className='table-data  w-16'>
                                UOM
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {localItems.map((item, index) =>
                            <tr key={index} className='table-row'>
                                <td className='table-data'>
                                    <input type="checkbox" className='w-full table-data-input' onChange={(e) =>
                                        handleCheckBoxChange(!isItemChecked(item), item)}
                                        checked={isItemChecked(item)} />
                                </td>
                                <td className=' table-data  '>
                                    {count++}
                                </td>
                                <td className=' table-data  text-right'>
                                    {stringToLiteral(item?.item_group || '')}
                                </td>
                                <td className=' table-data  '>
                                    {stringToLiteral(item?.assy_mark || '')}
                                </td>
                                <td className=' table-data  '>
                                    {stringToLiteral(item?.name || '')}
                                </td>
                                <td className=' table-data  '>
                                    {stringToLiteral(item?.profile || '')}
                                </td>
                                <td className=' table-data  '>
                                    {stringToLiteral(item?.qty || '')}
                                </td>
                                <td className='table-data'>
                                    {stringToLiteral(item?.area || '')}
                                </td>
                                <td className=' table-data  text-right'>
                                    {stringToLiteral(item?.unit_weight || '')}
                                </td>
                                <td className=' table-data  text-right'>
                                    {stringToLiteral(item?.weight || '')}
                                </td>
                                <td className=' table-data  text-right'>
                                    {stringToLiteral(item?.length || '')}
                                </td>
                                <td className=' table-data  text-right'>
                                    {stringToLiteral(item?.uom || '')}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className='flex justify-end gap-10 p-2 text-sm'>
                <button className='p-1 bg-red-400 rounded-lg' onClick={onBack}>Back</button>
                <button className='p-1 bg-blue-400 rounded-lg' onClick={onDone}>Done</button>
            </div>
        </div>

    )
}

export default SelectionGrid