import React, { useEffect, useState, useRef, useCallback } from "react";
import {
    useGetProjectImportQuery,
    useGetProjectImportByIdQuery,
    useAddProjectImportMutation,
    useUpdateProjectImportMutation,
    useDeleteProjectImportMutation,
} from "../../../redux/Construction/ProjectImportServices";
import { useGetPartyQuery } from "../../../redux/ErpServices/PartyMasterServices";
import FormHeader from "../../../Basic/components/FormHeader";
import { toast } from "react-toastify";
import { DisabledInput, DateInput, DropdownInput } from "../../../Inputs";
import { dropDownListObject, } from '../../../Utils/contructObject';

import Modal from "../../../UiComponents/Modal";
import FormReport from "./FormReport";

import { getCommonParams, isGridDatasValid } from "../../../Utils/helper";
import ProjectNameSelectAndCreateComponent from "../../../ERP/ReusableComponents/ProjectNameSelectAndCreateComponent";
import ProjectPhaseSelectAndCreateComponent from "../../../ERP/ReusableComponents/ProjectPhaseSelectAndCreateComponent";
import ExcelSelectionTable from "./ExcelSelectionTable";
import moment from "moment";
import ProjectPhaseDetails from "./ProjectPhaseDetails";
const MODEL = "Project Import";

export default function Form() {

    const [readOnly, setReadOnly] = useState(false);
    const [docId, setDocId] = useState("")
    const [id, setId] = useState("");
    const [file, setFile] = useState(null);
    const [formReport, setFormReport] = useState(false);
    const [projectId, setProjectId] = useState("");
    const [phaseId, setPhaseId] = useState("");
    const [partyId, setPartyId] = useState("");
    const [date, setDate] = useState("");
    const [projectPhaseDetails, setProjectPhaseDetails] = useState([]);
    const [pres, setPres] = useState([])

    const childRecord = useRef(0);

    const { branchId, companyId, finYearId, userId } = getCommonParams()

    const branchIdFromApi = useRef(branchId);
    const params = {
        branchId, companyId, finYearId
    };

    const { data: supplierList } =
        useGetPartyQuery({ params: { ...params } });

    const { data: allData, isLoading, isFetching } = useGetProjectImportQuery({ params, searchParams: '' });

    const getNextDocId = useCallback(() => {
        if (id || isLoading || isFetching) return
        if (allData?.nextDocId) {
            setDocId(allData.nextDocId)
        }
    }, [allData, isLoading, isFetching, id])

    useEffect(getNextDocId, [getNextDocId])

    const {
        data: singleData,
        isFetching: isSingleFetching,
        isLoading: isSingleLoading,
    } = useGetProjectImportByIdQuery(id, { skip: !id });

    const [addData] = useAddProjectImportMutation();
    const [updateData] = useUpdateProjectImportMutation();
    const [removeData] = useDeleteProjectImportMutation();

    const syncFormWithDb = useCallback((data) => {
        if (id) {
            setReadOnly(true);
        } else {
            setReadOnly(false);
        }
        if (data?.docId) {
            setDocId(data?.docId)
        }
        setDate(data?.createdAt ? moment(data?.createdAt).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"));
        setPartyId(data?.ProjectPhase?.Project?.partyId ? data?.ProjectPhase?.Project?.partyId : "");
        setProjectId(data?.ProjectPhase?.projectId ? data?.ProjectPhase?.projectId : "");
        setPhaseId(data?.projectPhaseId ? data?.projectPhaseId : "");
        setProjectPhaseDetails(data?.ProjectPhaseDetails || []);
        if (data?.branchId) {
            branchIdFromApi.current = data?.branchId
        }
    }, [id]);



    useEffect(() => {
        if (id) {
            syncFormWithDb(singleData?.data);
        } else {
            syncFormWithDb(undefined);
        }
    }, [isSingleFetching, isSingleLoading, id, syncFormWithDb, singleData]);

    const data = {
        branchId, id, userId,
        finYearId,
        projectPhaseId: phaseId
    }


    const validateData = (data) => {
        let mandatoryFields = ["assy_mark",
            "name",
            "qty",
            "item_group",
            "hsn", "tax", "rate", "uom"];
        return data.projectPhaseId && isGridDatasValid(pres, false, mandatoryFields)
    }

    const handleSubmitCustom = async (callback, data, text) => {
        try {
            const formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }
            if (file instanceof File) {
                formData.append("file", file);
            }
            let returnData;
            if (text === "Updated") {
                returnData = await callback(formData).unwrap();
            } else {
                returnData = await callback(formData).unwrap();
            }
            if (returnData?.statusCode === 0) {
                onNew()
                toast.success(text + "Successfully");
            } else {
                toast.error(returnData.message);
            }
        } catch (error) {
            console.log("handle");
        }
    };


    const saveData = () => {
        if (!validateData(data)) {
            toast.info("Please fill all required fields...!", { position: "top-center" })
            return
        }
        if (id) {
            handleSubmitCustom(updateData, data, "Updated");
        } else {
            handleSubmitCustom(addData, data, "Added");
        }
    }

    const deleteData = async () => {
        if (id) {
            if (!window.confirm("Are you sure to delete...?")) {
                return;
            }
            try {
                await removeData(id)
                setId("");
                onNew();
                toast.success("Deleted Successfully");
            } catch (error) {
                toast.error("something went wrong");
            }
        }
    };

    const handleKeyDown = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "s") {
            event.preventDefault();
            saveData();
        }
    };

    const onNew = () => {
        setId("");
        setReadOnly(false);
        syncFormWithDb(undefined)
        getNextDocId();
        setPres([]);
    };


    const tableHeadings = ["PoNo", "PoDate", "transType", "DueDate", "Supplier"]
    const tableDataNames = ['dataObj?.id', 'dataObj.active ? ACTIVE : INACTIVE']

    let supplierListBasedOnSupply = supplierList ? supplierList.data : []
    return (
        <div
            onKeyDown={handleKeyDown}
            className="md:items-start md:justify-items-center grid h-full bg-theme overflow-auto">
            <Modal isOpen={formReport} onClose={() => setFormReport(false)} widthClass={"px-2 h-[90%] w-[90%]"}>
                <FormReport
                    heading={MODEL}
                    tableHeaders={tableHeadings}
                    tableDataNames={tableDataNames}
                    loading={
                        isLoading || isFetching
                    }
                    tableWidth="100%"
                    data={allData?.data}
                    onClick={(id) => {
                        setId(id);
                        setFormReport(false);
                    }
                    }
                />
            </Modal>
            <div className="flex flex-col frame w-full h-full">
                <FormHeader
                    onNew={onNew}
                    model={MODEL}
                    saveData={saveData}
                    setReadOnly={setReadOnly}
                    deleteData={deleteData}
                    openReport={() => { setFormReport(true) }}
                    childRecord={childRecord.current}
                />
                <div className="flex-1 grid gap-x-2">
                    <div className="col-span-3 grid ">
                        <div className='col-span-3 grid '>
                            <div className='mr-1'>
                                <div className={`grid`}>
                                    <div className={"flex flex-col"}>
                                        <fieldset className='frame rounded-tr-lg rounded-bl-lg w-full border border-gray-600 px-3 min-h-[100px]'>
                                            <legend className='sub-heading'>Purchase Info</legend>
                                            <div className='flex flex-col justify-center items-start flex-1 w-full'>
                                                <div className="grid grid-cols-5 w-full">
                                                    <DisabledInput name="Doc Id." value={docId} required={true}
                                                    />
                                                    <DateInput name="Doc Date" value={date} type={"date"} required={true} readOnly={readOnly} disabled />
                                                    <DropdownInput name="Customer" options={dropDownListObject(supplierListBasedOnSupply, "aliasName", "id")} value={partyId} setValue={setPartyId} required={true} readOnly={id} />
                                                    <ProjectNameSelectAndCreateComponent readOnly={readOnly || (!partyId)} partyId={partyId} projectId={projectId} setProjectId={setProjectId} />
                                                    <ProjectPhaseSelectAndCreateComponent
                                                        readOnly={readOnly || !(projectId)}
                                                        projectId={projectId}
                                                        projectPhaseId={phaseId}
                                                        setProjectPhaseId={setPhaseId}
                                                    />
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset className='frame rounded-tr-lg rounded-bl-lg rounded-br-lg my-1 w-full border border-gray-600 md:pb-5 flex flex-1 overflow-auto'>
                                            <legend className='sub-heading'>Import Details</legend>
                                            {id ?
                                                <ProjectPhaseDetails projectPhaseDetails={projectPhaseDetails} />
                                                :
                                                <ExcelSelectionTable pres={pres} setPres={setPres} params={params} readOnly={readOnly} file={file} setFile={setFile} />
                                            }
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}