import React, { useEffect, useState } from 'react'
import useOutsideClick from '../../CustomHooks/handleOutsideClick';
import { findFromList } from '../../Utils/helper';

const SelectAndCreateComponent = ({ setItemId, itemId, handleAddNewProject, name, options, searchParameter = "name", readOnly }) => {
    const [isListShow, setIsListShow] = useState(false)
    const inputRef = useOutsideClick(() => { setIsListShow(false) })
    const [filteredOptions, setFilteredOptions] = useState([])
    const [search, setSearch] = useState("");

    useEffect(() => {
        let pageSearchComponent = document.getElementById(name);
        if (!pageSearchComponent) return
        pageSearchComponent.addEventListener('keydown', function (ev) {
            var focusableElementsString = '[tabindex="0"]';
            let ol = document.querySelectorAll(focusableElementsString);
            if (ev.key === "ArrowDown") {
                for (let i = 0; i < ol.length; i++) {
                    if (ol[i] === ev.target) {
                        let o = i < ol.length - 1 ? ol[i + 1] : ol[0];
                        o.focus(); break;
                    }
                }
                ev.preventDefault();
            } else if (ev.key === "ArrowUp") {
                for (let i = 0; i < ol.length; i++) {
                    if (ol[i] === ev.target) {
                        let o = ol[i - 1];
                        o.focus(); break;
                    }
                }
                ev.preventDefault();
            }
        });
        return () => {
            pageSearchComponent.removeEventListener('keydown', () => { });
        };
    }, []);



    useEffect(() => {
        if (!options) return
        if (!search) { setFilteredOptions(options.data) }
        setFilteredOptions(options.filter(item => item[searchParameter].toLowerCase().includes(search.toLowerCase())))
    }, [search, options, searchParameter])

    const handleAddNewOption = async () => {
        let responseId = await handleAddNewProject(search)
        setItemId(responseId)
    }

    return (
        <div id={name} className='relative flex flex-col text-black z-0 w-full' ref={inputRef}>
            <div className='grid grid-cols-1 md:grid-cols-3 items-center md:my-0.5 md:px-1 data gap-1'>
                <label className={`md:text-start flex`}>{name}</label>
                <div className='col-span-2 z-0'>
                    {(isListShow) ?
                        <input type="text" className={`input-field focus:outline-none border-gray-500 border rounded`}
                            value={search}
                            disabled={readOnly}
                            onChange={(e) => { setSearch(e.target.value.toUpperCase()) }}
                            onFocus={() => { setIsListShow(true) }}
                            tabIndex={0}
                        />
                        :
                        <input type="text" className={`input-field focus:outline-none border-gray-500 border rounded`}
                            value={findFromList(itemId, options, searchParameter)}
                            disabled={readOnly}
                            tabIndex={0}
                            onFocus={() => { setIsListShow(true) }}
                        />
                    }
                    {(isListShow && (!readOnly)) &&
                        <ul className='absolute max-h-[300px] z-50 overflow-auto top-7 bg-gray-200 w-[180px]'>
                            {search &&
                                <li
                                    tabIndex={0}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleAddNewOption()
                                            setSearch("");
                                            setIsListShow((false));
                                        }
                                    }}
                                    onClick={() => {
                                        handleAddNewOption()
                                        setSearch(""); setIsListShow((false));
                                    }}
                                >Create Party {`"${search}"`}</li>
                            }
                            {filteredOptions.map((party) => <li className='cursor-pointer'
                                key={party.id}
                                tabIndex={0}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        setItemId(party.id)
                                        setSearch("");
                                        setIsListShow((false));
                                    }
                                }}
                                onClick={() => { setItemId(party.id); setSearch(""); setIsListShow((false)); }} > <pre> {party.name} </pre></li>)}
                        </ul>
                    }
                </div>
            </div>
        </div>
    )
}

export default SelectAndCreateComponent
