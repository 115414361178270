import React from 'react'
import { convertSpaceToUnderScore } from '../../../Utils/helper'

const ProjectPhaseDetails = ({ projectPhaseDetails }) => {

    const header = [
        "assy mark",
        "name",
        "profile",
        "qty",
        "area",
        "unit weight",
        "weight",
        "length",
        "item group",
        "hsn",
        "tax",
        "rate",
        "uom"
    ]
    return (
        <div className="w-full">
            <div className="w-full flex flex-col gap-5">
                <div className="mt-3 flex flex-col justify-start items-start gap-10">

                    <table className="w-full">
                        <thead>
                            <tr>
                                <th className="border border-gray-400 text-sm py-1">S.No</th>
                                {header.map((columnName, index) => (
                                    <th className="border border-gray-400 text-sm py-1" key={index}>{convertSpaceToUnderScore(columnName)}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {projectPhaseDetails.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td className="border border-gray-400 text-sm py-1">{rowIndex + 1}</td>
                                    {header.map((columnName, columnIndex) => (
                                        <td className="border border-gray-400 text-xs py-1" key={columnIndex}>{row[convertSpaceToUnderScore(columnName)] === "undefined" ?
                                            '' : row[convertSpaceToUnderScore(columnName)]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ProjectPhaseDetails